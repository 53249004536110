/*!
 * Prins Grundsystem-2023 2024.08.12-01
 * Management von Mitteilungen bezüglich Presse- und Öffentlichkeitsarbeit.
 * https://www.ifabrik.de/
 *
 * Copyright 2023 i-fabrik GmbH
 *
 * Released on August 9, 2024
 * 
 * Author: i-fabrik GmbH
 * Contributor: Heiko Pfefferkorn <heiko.pfefferkorn@ifabrik.de>
 */
@charset "UTF-8";
/**
 * Überschriftselektoren zusammenstellen<br/>
 *
 * @example scss
 *   $var : heading-selectors();
 *   // = h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6
 *
 *   $var : heading-selectors(1, 6, false);
 *   // = h1, h2, h3, h4, h5, h6
 *
 *   $var : heading-selectors(3, 4);
 *   // = h3, .h3, h4, .h4
 *
 *   // Abfangen von nicht korrekten Angaben (`< 1`, `> 6`).
 *   $var : heading-selectors(0, 0);
 *   $var : heading-selectors(-1, 0);
 *   $var : heading-selectors(7, -15);
 *   // = h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6
 *
 *   $var : heading-selectors(0, 2);
 *   // = h1, .h1, h2, .h2
 *
 *   // Vertauschte Werte.
 *   $var : heading-selectors(3, 1);
 *   // = h3, .h3, h4, .h4, h5, .h5, h6, .h6
 */
/**
 * Prüft ob `$val` null ist.
 *
 * @example scss
 *   $var : is-null(null);
 *   // = true
 *
 *   $var : is-null(0);
 *   // = false
 */
/**
 * Prüft ob `$val` leer ist.
 *
 * @example scss
 *   $var : is-empty();
 *   // = true
 *
 *   $var : is-empty('');
 *   // = true
 *
 *   $var : is-empty('foo');
 *   // = false
 */
/**
 * Prüft ob `$val` numerisch ist.
 *
 * @example scss
 *   $var : is-numeric(1);
 *   // = true
 *
 *   $var : is-numeric('2');
 *   // = false
 *
 *   $var : is-numeric(3rem);
 *   // = false
 */
/**
 * Prüft ob `$val` boolean ist.
 *
 * @example scss
 *   $var : is-bool(true);
 *   // = true
 *
 *   $var : is-bool(false);
 *   // = false
 *
 *   $var : is-bool(1);
 *   // = true
 *
 *   $var : is-bool(0);
 *   // = false
 *
 *   $var : is-bool(null);
 *   // = false
 */
/**
 * Prüft ob `$val` ein String ist.
 *
 * @example scss
 *   $var : is-string(foo);
 *   // = true
 *
 *   $var : is-string('bar');
 *   // = true
 *
 *   $var : is-string(0);
 *   // = false
 */
/**
 * Prüft ob `$val` eine Liste ist.
 *
 * @example scss
 *   $var : is-list(a b c d);
 *   // = true
 *
 *   $var : is-list(lorem, ipsum);
 *   // = true
 *
 *   $var : is-list(foo);
 *   // = false
 */
/**
 * Prüft ob `$val` eine Map ist.
 *
 * @example scss
 *   $var : is-map((a: b));
 *   // = true
 *
 *   $var : is-list(foo);
 *   // = false
 */
/**
 * Replace `$search` with `$replace` in `$string`
 */
/**
 * SVG-XML-String kodieren
 */
/**
 * Einheit eines Wertes entfernen.
 *
 * @example
 *   $var : strip-unit(16px);
 *   // = 16
 */
/**
 * Einheit eines Wertes entfernen.
 *
 * @example
 *   $var : get-unit(16px);
 *   // = px
 */
/**
 * Höhenangabe ´vh´ auf Basis einer Rootvariable, wird per JS gesetzt berechnen.
 * (https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html)
 *
 * @example scss
 *   // Rootvariable `--vh` wurde per JS mit dem Wert ´6.4px´ (Browserhöhe ist 640px) gesetzt
 *
 *   $var : vh(50);
 *   // = calc(var(--vh, 1vh) * 50); // berechnet wird demzufolge 320px
 *
 *   // Mit Prefix der Rootvariable (`--ws-vh`)
 *
 *   $var : vh(25, 'ws-');
 *   // = calc(var(--ws-vh, 1vh) * 25); // berechnet wid demzufolge 160px
 */
/**
 * Farbton (Hue) eines Hexwertes zurückgeben.
 *
 * @example
 *   get-hue(#6176ff);
 *   // = 232
 */
/**
 * Sättigung (Saturation) eines Hexwertes zurückgeben.
 *
 * @example
 *   get-saturation(#6176ff);
 *   // = 100%
 */
/**
 * Helligkeit (Lightness) eines Hexwertes zurückgeben.
 *
 * @example
 *   get-lightness(#6176ff);
 *   // = 69%
 */
/**
 * Überschreiben der SCSS-Konvertierungsfunktion `hsl()` zu einer realen
 * `hsl()`-Angabe.
 *
 * @example
 *   hsl(232, 100%, 69%);
 *   // = hsl(232, 100%, 69%)
 *
 *   hsl(232, 100, 69);
 *   // = hsl(232, 100%, 69%)
 *
 *   hsl(#6176ff);
 *   // = hsl(232, 100%, 69%)
 *
 *   hsl(var(--color-hue), 100%, 69%);
 *   // = hsl(var(--color-hue), 100%, 69%)
 *
 *   hsl(var(--color-hue), var(--color-saturation), var(--color-lightness));
 *   // = hsl(var(--color-hue), var(--color-saturation), var(--color-lightness))
 */
/**
 * Überschreiben der SCSS-Konvertierungsfunktion `hsla()` zu einer realen
 * `hsla()`-Angabe.
 *
 * @example
 *   hsla(232, 100%, 69%, 0.75);
 *   // = hsla(232, 100%, 69%, 0.75)
 *
 *   hsla(232, 100, 69, 0.75);
 *   // = hsla(232, 100%, 69%, 0.75)
 *
 *   hsla(#6176ff, 0.75);
 *   // = hsla(232, 100%, 69%, 0.75)
 *
 *   hsla(#6176ff);
 *   // = hsla(232, 100%, 69%, 1)
 *
 *   hsla(var(--color-hue), 100%, 69%);
 *   // = hsla(var(--color-hue), 100%, 69%, 1)
 *
 *   hsla(var(--color-hue), var(--color-saturation), var(--color-lightness));
 *   // = hsla(var(--color-hue), var(--color-saturation), var(--color-lightness), 1)
 *
 *   hsla(#6176ff, var(--opacity));
 *   // = hsla(232, 100%, 69%, var(--opacity))
 */
/**
 * Überschreiben der SCSS-Konvertierungsfunktion `rgb()` zu einer realen
 * `rgb()`-Angabe.
 *
 * @example
 *   rgb(97, 118, 255);
 *   // = rgb(97, 118, 255)
 *
 *   rgb(#6176ff);
 *   // = rgb(97, 118, 255)
 *
 *   // `--color : 97, 118, 255;`
 *   rgb(var(--color));
 *   // = rgb(var(--color))
 */
/**
 * Überschreiben der SCSS-Konvertierungsfunktion `rgba()` zu einer realen
 * `rgba()`-Angabe.
 *
 * @example
 *   rgba(97, 118, 255, 0.75);
 *   // = rgba(97, 118, 255, 0.75)
 *
 *   rgba(97, 118, 255);
 *   // = rgba(97, 118, 255, 1)
 *
 *   rgba(#6176ff, 0.75);
 *   // = rgba(97, 118, 255, 0.75)
 *
 *   rgba(#6176ff);
 *   // = rgba(97, 118, 255, 1)
 *
 *   // `--color : 97, 118, 255;`
 *   rgba(var(--color));
 *   // = rgba(var(--color), 1)
 *
 *   // `--color : 97, 118, 255;`
 *   // `--opcity : 0.75;`
 *   rgba(var(--color), var(--opacity));
 *   // = rgba(var(--color), var(--opacity))
 */
/**
 * Minimale Breakpointweite.<br/>
 * <small>_Null für den kleinsten (ersten) Breakpoint._</small>
 *
 * @example scss
 *   $breakpoints : (
 *     'xxs' : 0,
 *     'xs'  : 420px,
 *     's'   : 576px,
 *     'm'   : 768px,
 *     'l'   : 992px
 *   );
 *
 *   $var : breakpoint-min('xs');
 *   // = 420px
 */
/**
 * Maximale Breakpointweite.<br/>
 * <small>_Null für den größten (letzten) Breakpoint. Der Maximalwert wird als Minimum des nächsten Breakpointwertes minus 1px berechnet._</small>
 *
 * @example scss
 *   $breakpoints : (
 *     'xxs' : 0,
 *     'xs'  : 420px,
 *     's'   : 576px,
 *     'm'   : 768px,
 *     'l'   : 992px
 *   );
 *
 *   $var : breakpoint-max('xs');
 *   // = 575px
 */
/**
 * Name des nächsten Breakpoints.<br/>
 * <small>_Null für den letzten Breakpoint._</small>
 *
 * @example scss
 *   $breakpoints : (
 *     'xxs' : 0,
 *     'xs'  : 420px,
 *     's'   : 576px,
 *     'm'   : 768px,
 *     'l'   : 992px
 *   );
 *
 *   $var : breakpoint-next('xs');
 *   // = s
 *
 *   $var : breakpoint-next('s');
 *   // = m
 */
/**
 * Anwendung von Definitionen (`@content`) ab dem Breakpoint `$name` und höher
 * (mobile first).
 *
 * @example scss
 *   $breakpoints : (
 *     'xxs' : 0,
 *     'xs'  : 420px,
 *     's'   : 576px,
 *     'm'   : 768px,
 *     'l'   : 992px
 *   );
 *
 *   .container {
 *     background : #fff;
 *
 *     @include media-breakpoint-up('s') {
 *       background : #ccc;
 *     }
 *   }
 *
 * @example css - Result
 *   .container {
 *     background : #fff;
 *   }
 *
 *   @media (min-width: 576px) {
 *     .container {
 *       background : #ccc;
 *     }
 *   }
 */
/**
 * Anwendung von Definitionen (`@content`) ab der Maximalbreite des Breakpoint
 * `$name` und kleiner (desktop first).
 *
 * @example scss
 *   $breakpoints : (
 *     'xxs' : 0,
 *     'xs'  : 420px,
 *     's'   : 576px,
 *     'm'   : 768px,
 *     'l'   : 992px
 *   );
 *
 *   .container {
 *     background : #fff;
 *
 *     @include media-breakpoint-down('s') {
 *       background : #ccc;
 *     }
 *   }
 *
 * @example css - Result
 *   .container {
 *     background : #fff;
 *   }
 *
 *   @media (max-width: 767px) {
 *     .container {
 *       background : #ccc;
 *     }
 *   }
 */
/**
 * Shortcut-Funktion für `media-breakpoint-up()` und `media-breakpoint-down()`.
 */
/**
 * Anwendung von Definitionen (`@content`) zwischen Minimumbreite `$lower` und
 * Maximumbreite `$upper`.
 *
 * @example scss
 *   $breakpoints : (
 *     'xxs' : 0,
 *     'xs'  : 420px,
 *     's'   : 576px,
 *     'm'   : 768px,
 *     'l'   : 992px
 *   );
 *
 *   .container {
 *     background : #fff;
 *
 *     @include media-breakpoint-between('xs', 'm') {
 *       background : #ccc;
 *     }
 *   }
 *
 * @example css - Result
 *   .container {
 *     background : #fff;
 *   }
 *
 *   @media (min-width : 420px) and (max-width : 991px) {
 *     .container {
 *       background : #ccc;
 *     }
 *   }
 */
/**
 * Anwendung von Definitionen (`@content`) nur innerhalb der Minimum- und
 * Maxiamlbreite des Breakpoints `$name`.
 *
 * @example scss
 *   $breakpoints : (
 *     'xxs' : 0,
 *     'xs'  : 420px,
 *     's'   : 576px,
 *     'm'   : 768px,
 *     'l'   : 992px
 *   );
 *
 *   .container {
 *     background : #fff;
 *
 *     @include media-breakpoint-only('s') {
 *       background : #ccc;
 *     }
 *   }
 *
 * @example css - Result
 *   .container {
 *     background : #fff;
 *   }
 *
 *   @media (min-width: 576px) and (max-width: 767px) {
 *     .container {
 *       background : #ccc;
 *     }
 *   }
 */
/**
 * Kontextbasierten Selektor etwas vereinfachen ;).
 */
/**
 * On `:disabled`
 */
/**
 * On `.not(:disabled)`
 */
/**
 * On `:readonly`
 */
/**
 * On `:hover`
 */
/**
 * On `:active`
 */
/**
 * On `:focus`
 */
/**
 * On `:hover, :focus`
 */
/**
 * On `:hover, :active, :focus`
 */
/**
 * Webfonts mit `@font-face` integrieren.
 *
 * Bzgl. moderner Browser (Stand Anfang 2019) wird normalerweise nur `woff2` und
 * `woff` benötigt. Eine Prüfung auf das Schriftformat bzgl. einer Erweiterung
 * der URL wird nur für `eot` und `ttf` durchgeführt!
 *
 * `woff2` moderne Browser
 * `woff` IE 11
 * `eot` IE6-IE8
 * `ttf` Safari, Android, iOS
 *
 * @example scss
 *   @include font-face(
 *     'Example-Regular',
 *     'example/',
 *     (
 *       woff2 : 'example-regular.woff2',
 *       woff  : 'example-regular.woff',
 *       eot   : 'example-regular.eot',
 *       ttf   : 'example-regular.ttf'
 *     )
 *   );
 *
 * @example css - Result
 *   @font-face {
 *     font-family : 'Example-Regular';
 *     font-display: swap;
 *     src         : url('../example/example-regular.woff2') format('woff2'),
 *                   url('../example/example-regular.woff') format('woff'),
 *                   url('../example/example-regular.eot?#iefix') format('embedded-opentype'),
 *                   url('../example/example-regular.ttf') format('truetype');
 *   }
 */
/**
 * Generieren von Selektoren bzgl. Validierungsstatus
 */
/* stylelint-disable-line scss/dollar-variable-pattern */
/* stylelint-disable-line scss/dollar-variable-pattern */
/**
 * Scrollen eines Containers deaktivieren.
 */
/**
 * Helper class integrieren.
 */
/**
 * Textelement bei Klick vollständig ausgewählen.
 */
/**
 * Textelement nicht auswählbar.
 */
/**
 * ´Aufspannen´ eines Links.
 * Ein Elternelement muss dazu mit `position: relative;` definiert sein!
 */
/**
 * Inhalte visuell ausblenden aber für unterstützende Technologien zugänglich
 * halten.
 */
/**
 * Zeige Inhalt nur wenn er fokussiert wird/wurde.
 */
/* stylelint-disable-line length-zero-no-unit */
:root {
  --ifabsh-color-black-h: 0;
  --ifabsh-color-black-s: 0%;
  --ifabsh-color-black-l: 0%;
  --ifabsh-color-black: hsl(var(--ifabsh-color-black-h), var(--ifabsh-color-black-s), var(--ifabsh-color-black-l));
  --ifabsh-color-white-h: 0;
  --ifabsh-color-white-s: 0%;
  --ifabsh-color-white-l: 100%;
  --ifabsh-color-white: hsl(var(--ifabsh-color-white-h), var(--ifabsh-color-white-s), var(--ifabsh-color-white-l));
  --ifabsh-color-grey-h: 0;
  --ifabsh-color-grey-s: 0%;
  --ifabsh-color-grey-l: 91%;
  --ifabsh-color-grey: hsl(var(--ifabsh-color-grey-h), var(--ifabsh-color-grey-s), var(--ifabsh-color-grey-l));
  --ifabsh-color-grey-light-h: 0;
  --ifabsh-color-grey-light-s: 0%;
  --ifabsh-color-grey-light-l: 95%;
  --ifabsh-color-grey-light: hsl(var(--ifabsh-color-grey-light-h), var(--ifabsh-color-grey-light-s), var(--ifabsh-color-grey-light-l));
  --ifabsh-color-border-h: 0;
  --ifabsh-color-border-s: 0%;
  --ifabsh-color-border-l: 91%;
  --ifabsh-color-border: hsl(var(--ifabsh-color-border-h), var(--ifabsh-color-border-s), var(--ifabsh-color-border-l));
  --ifabsh-color-placeholder-h: 0;
  --ifabsh-color-placeholder-s: 0%;
  --ifabsh-color-placeholder-l: 58%;
  --ifabsh-color-placeholder: hsl(var(--ifabsh-color-placeholder-h), var(--ifabsh-color-placeholder-s), var(--ifabsh-color-placeholder-l));
  --ifabsh-color-text-h: 0;
  --ifabsh-color-text-s: 0%;
  --ifabsh-color-text-l: 24%;
  --ifabsh-color-text: hsl(var(--ifabsh-color-text-h), var(--ifabsh-color-text-s), var(--ifabsh-color-text-l));
  --ifabsh-color-ui-h: 232;
  --ifabsh-color-ui-s: 100%;
  --ifabsh-color-ui-l: 69%;
  --ifabsh-color-ui: hsl(var(--ifabsh-color-ui-h), var(--ifabsh-color-ui-s), var(--ifabsh-color-ui-l));
  --ifabsh-color-ui-contrast-h: 0;
  --ifabsh-color-ui-contrast-s: 0%;
  --ifabsh-color-ui-contrast-l: 100%;
  --ifabsh-color-ui-contrast: hsl(var(--ifabsh-color-ui-contrast-h), var(--ifabsh-color-ui-contrast-s), var(--ifabsh-color-ui-contrast-l));
  --ifabsh-color-indeterminate-h: 37;
  --ifabsh-color-indeterminate-s: 98%;
  --ifabsh-color-indeterminate-l: 53%;
  --ifabsh-color-indeterminate: hsl(var(--ifabsh-color-indeterminate-h), var(--ifabsh-color-indeterminate-s), var(--ifabsh-color-indeterminate-l));
  --ifabsh-color-danger-h: 355;
  --ifabsh-color-danger-s: 96%;
  --ifabsh-color-danger-l: 61%;
  --ifabsh-color-danger: hsl(var(--ifabsh-color-danger-h), var(--ifabsh-color-danger-s), var(--ifabsh-color-danger-l));
  --ifabsh-color-danger-contrast-h: 0;
  --ifabsh-color-danger-contrast-s: 0%;
  --ifabsh-color-danger-contrast-l: 100%;
  --ifabsh-color-danger-contrast: hsl(var(--ifabsh-color-danger-contrast-h), var(--ifabsh-color-danger-contrast-s), var(--ifabsh-color-danger-contrast-l));
  --ifabsh-color-success-h: 158;
  --ifabsh-color-success-s: 80%;
  --ifabsh-color-success-l: 42%;
  --ifabsh-color-success: hsl(var(--ifabsh-color-success-h), var(--ifabsh-color-success-s), var(--ifabsh-color-success-l));
  --ifabsh-color-success-contrast-h: 0;
  --ifabsh-color-success-contrast-s: 0%;
  --ifabsh-color-success-contrast-l: 100%;
  --ifabsh-color-success-contrast: hsl(var(--ifabsh-color-success-contrast-h), var(--ifabsh-color-success-contrast-s), var(--ifabsh-color-success-contrast-l));
  --ifabsh-color-warning-h: 37;
  --ifabsh-color-warning-s: 98%;
  --ifabsh-color-warning-l: 53%;
  --ifabsh-color-warning: hsl(var(--ifabsh-color-warning-h), var(--ifabsh-color-warning-s), var(--ifabsh-color-warning-l));
  --ifabsh-color-warning-contrast-h: 0;
  --ifabsh-color-warning-contrast-s: 0%;
  --ifabsh-color-warning-contrast-l: 0%;
  --ifabsh-color-warning-contrast: hsl(var(--ifabsh-color-warning-contrast-h), var(--ifabsh-color-warning-contrast-s), var(--ifabsh-color-warning-contrast-l));
  --ifabsh-color-info-h: 189;
  --ifabsh-color-info-s: 64%;
  --ifabsh-color-info-l: 49%;
  --ifabsh-color-info: hsl(var(--ifabsh-color-info-h), var(--ifabsh-color-info-s), var(--ifabsh-color-info-l));
  --ifabsh-color-info-contrast-h: 0;
  --ifabsh-color-info-contrast-s: 0%;
  --ifabsh-color-info-contrast-l: 0%;
  --ifabsh-color-info-contrast: hsl(var(--ifabsh-color-info-contrast-h), var(--ifabsh-color-info-contrast-s), var(--ifabsh-color-info-contrast-l));
  --ifabsh-focus-outline-width: 1px;
  --ifabsh-focus-outline-style: dotted;
  --ifabsh-focus-outline-color: #c90;
  --ifabsh-focus-outline-offset: 2px;
  --ifabsh-backdrop-color: var(--ifabsh-color-black);
  --ifabsh-backdrop-filter: none;
  --ifabsh-backdrop-opacity: 0.5;
  --ifabsh-font-size: 1rem;
  --ifabsh-font-size-xs: 0.5rem;
  --ifabsh-font-size-s: 0.75rem;
  --ifabsh-font-size-l: 1.25rem;
  --ifabsh-spacing: 1rem;
  --ifabsh-spacing-xxs: 0.125rem;
  --ifabsh-spacing-xs: 0.25rem;
  --ifabsh-spacing-s: 0.5rem;
  --ifabsh-spacing-l: 1.25rem;
  --ifabsh-breakpoint-xs: 0;
  --ifabsh-breakpoint-s: 576px;
  --ifabsh-breakpoint-m: 768px;
  --ifabsh-breakpoint-l: 992px;
  --ifabsh-breakpoint-xl: 1200px;
  --ifabsh-border-radius: 0px;
  --ifabsh-border-width: 1px;
  --ifabsh-input-background-color: var(--ifabsh-color-white);
  --ifabsh-input-color: var(--ifabsh-color-black);
  --ifabsh-input-border-color: var(--ifabsh-color-border);
  --ifabsh-input-border-width: var(--ifabsh-border-width);
  --ifabsh-input-disabled-background-color: var(--ifabsh-color-white);
  --ifabsh-input-disabled-opacity: var(--ifabsh-disabled-opacity, 0.4);
  --ifabsh-input-readonly-background-color: var(--ifabsh-color-grey-light);
  --ifabsh-input-readonly-opacity: var(--ifabsh-readonly-opacity, 1);
  --ifabsh-input-height: 40px;
  --ifabsh-input-height-s: 30px;
  --ifabsh-input-height-l: 50px;
}

:root {
  --ifabsh-color-border-h: var(--prins-border-color-h);
  --ifabsh-color-border-s: var(--prins-border-color-s);
  --ifabsh-color-border-l: var(--prins-border-color-l);
  --ifabsh-color-text-h: var(--prins-color-black-h);
  --ifabsh-color-text-s: var(--prins-color-black-s);
  --ifabsh-color-text-l: var(--prins-color-black-l);
  --ifabsh-color-ui-h: var(--prins-color-primary-h);
  --ifabsh-color-ui-s: var(--prins-color-primary-s);
  --ifabsh-color-ui-l: var(--prins-color-primary-l);
  --ifabsh-color-ui-contrast-h: var(--prins-color-primary-contrast-h);
  --ifabsh-color-ui-contrast-s: var(--prins-color-primary-contrast-s);
  --ifabsh-color-ui-contrast-l: var(--prins-color-primary-contrast-l);
  --ifabsh-color-danger-h: var(--prins-color-danger-h);
  --ifabsh-color-danger-s: var(--prins-color-danger-s);
  --ifabsh-color-danger-l: var(--prins-color-danger-l);
  --ifabsh-color-danger-contrast-h: var(--prins-color-danger-contrast-h);
  --ifabsh-color-danger-contrast-s: var(--prins-color-danger-contrast-s);
  --ifabsh-color-danger-contrast-l: var(--prins-color-danger-contrast-l);
  --ifabsh-color-success-h: var(--prins-color-success-h);
  --ifabsh-color-success-s: var(--prins-color-success-s);
  --ifabsh-color-success-l: var(--prins-color-success-l);
  --ifabsh-color-success-contrast-h: var(--prins-color-success-contrast-h);
  --ifabsh-color-success-contrast-s: var(--prins-color-success-contrast-s);
  --ifabsh-color-success-contrast-l: var(--prins-color-success-contrast-l);
  --ifabsh-color-warning-h: var(--prins-color-warning-h);
  --ifabsh-color-warning-s: var(--prins-color-warning-s);
  --ifabsh-color-warning-l: var(--prins-color-warning-l);
  --ifabsh-color-warning-contrast-h: var(--prins-color-warning-contrast-h);
  --ifabsh-color-warning-contrast-s: var(--prins-color-warning-contrast-s);
  --ifabsh-color-warning-contrast-l: var(--prins-color-warning-contrast-l);
  --ifabsh-color-info-h: var(--prins-color-info-h);
  --ifabsh-color-info-s: var(--prins-color-info-s);
  --ifabsh-color-info-l: var(--prins-color-info-l);
  --ifabsh-color-info-contrast-h: var(--prins-color-info-contrast-h);
  --ifabsh-color-info-contrast-s: var(--prins-color-info-contrast-s);
  --ifabsh-color-info-contrast-l: var(--prins-color-info-contrast-l);
  --ifabsh-focus-outline-width: var(--prins-focus-outline-width);
  --ifabsh-focus-outline-style: var(--prins-focus-outline-style);
  --ifabsh-focus-outline-color: var(--prins-focus-outline-color);
  --ifabsh-focus-outline-offset: var(--prins-focus-outline-offset);
  --ifabsh-backdrop-color: var(--prins-backdrop-color);
  --ifabsh-backdrop-filter: var(--prins-backdrop-filter);
  --ifabsh-backdrop-opacity: var(--prins-backdrop-opacity);
  --ifabsh-font-size-xs: var(--prins-font-size-xs);
  --ifabsh-font-size-s: var(--prins-font-size-s);
  --ifabsh-font-size-l: var(--prins-font-size-l);
  --ifabsh-spacing: var(--prins-spacing);
  --ifabsh-spacing-xxs: var(--prins-spacing-xxs);
  --ifabsh-spacing-xs: var(--prins-spacing-xs);
  --ifabsh-spacing-s: var(--prins-spacing-s);
  --ifabsh-spacing-l: var(--prins-spacing-l);
  --ifabsh-border-radius: var(--prins-border-radius);
  --ifabsh-border-width: var(--prins-base-border-width);
  --ifabsh-input-background-color: var(--prins-color-white);
  --ifabsh-input-border-color: hsl(var(--prins-color-grey-h), var(--prins-color-grey-s), 83%);
  --ifabsh-input-border-width: var(--prins-border-width);
  --ifabsh-input-color: var(--prins-color-black);
  --ifabsh-input-disabled-background-color: var(--ifabsh-input-background-color);
  --ifabsh-input-disabled-opacity: var(--prins-base-disabled-opacity);
  --ifabsh-input-readonly-background-color: hsl(var(--prins-color-grey-h), var(--prins-color-grey-s), 96%);
  --ifabsh-input-readonly-opacity: 1;
  --ifabsh-input-height: 38px;
  --ifabsh-input-height-s: 30px;
  --ifabsh-input-height-l: 46px;
}

@media (max-width: 991px) {
  :root {
    --ifabsh-input-font-size: var(--ifabsh-font-size-s);
    --ifabsh-input-height: 34px;
    --ifabsh-input-padding: var(--ifabsh-spacing-s);
    --ifabsh-input-font-size-s: var(--ifabsh-font-size-xs);
    --ifabsh-input-height-s: 26px;
    --ifabsh-input-padding-s: var(--ifabsh-spacing-xs);
    --ifabsh-input-font-size-l: var(--ifabsh-font-size);
    --ifabsh-input-height-l: 42px;
    --ifabsh-input-padding-l: var(--ifabsh-spacing);
    --ifabsh-form-select-icon-gap-factor: 4;
  }
}
.badge {
  --ifabsh-badge-background-color: var(--ifabsh-color-grey, #e8e8e8);
  --ifabsh-badge-border-color: var(--ifabsh-color-grey, #e8e8e8);
  --ifabsh-badge-circle-size: var(--ifabsh-spacing-s, 0.5rem);
  --ifabsh-badge-color: var(--ifabsh-color-text, #3e3e3e);
  --ifabsh-badge-soft-factor: 0.1;
  --_badge-background-color: var(--ifabsh-badge-background-color);
  --_badge-border-color: var(--ifabsh-badge-border-color);
  --_badge-color: var(--ifabsh-badge-color);
  align-items: center;
  background-color: var(--_badge-background-color);
  border: var(--ifabsh-badge-border-width, var(--ifabsh-border-width, 1px)) solid var(--_badge-border-color);
  border-radius: var(--ifabsh-badge-border-radius, var(--ifabsh-border-radius, 0px));
  color: var(--_badge-color);
  cursor: inherit;
  display: inline-flex;
  font-size: var(--ifabsh-badge-font-size, var(--ifabsh-font-size-s, 0.75rem));
  font-weight: var(--ifabsh-badge-font-weight, normal);
  gap: 0.5em;
  justify-content: center;
  line-height: 1;
  padding: var(--ifabsh-badge-padding-y, var(--ifabsh-spacing-xs, 0.25rem)) var(--ifabsh-badge-padding-x, var(--ifabsh-spacing-s, 0.5rem));
  user-select: none;
  white-space: nowrap;
}
a.badge {
  text-decoration-line: none;
}
a.badge:link, a.badge:visited, a.badge:hover, a.badge:focus, a.badge:active {
  color: var(--ifabsh-badge-color);
}
a.badge:focus {
  box-shadow: none;
  outline: none;
}

.badge.-default.-soft {
  --_badge-background-color: hsla(var(--ifabsh-color-grey-h), var(--ifabsh-color-grey-s), var(--ifabsh-color-grey-l), var(--ifabsh-badge-soft-factor));
  --_badge-border-color: transparent;
}
.badge.-default.-soft.-outline {
  --_badge-border-color: var(--ifabsh-badge-border-color);
}

.badge.-primary {
  --ifabsh-badge-background-color: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-badge-border-color: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-badge-color: var(--ifabsh-color-ui-contrast, #fff);
}
.badge.-primary.-soft {
  --ifabsh-badge-color: var(--ifabsh-badge-background-color);
  --_badge-background-color: hsla(var(--ifabsh-color-ui-h), var(--ifabsh-color-ui-s), var(--ifabsh-color-ui-l), var(--ifabsh-badge-soft-factor));
  --_badge-border-color: transparent;
}
.badge.-primary.-soft.-outline {
  --_badge-border-color: var(--ifabsh-badge-border-color);
}

.badge.-info {
  --ifabsh-badge-background-color: var(--ifabsh-color-info, #2db5cd);
  --ifabsh-badge-border-color: var(--ifabsh-color-info, #2db5cd);
  --ifabsh-badge-color: var(--ifabsh-color-info-contrast, #000);
}
.badge.-info.-soft {
  --ifabsh-badge-color: var(--ifabsh-badge-background-color);
  --_badge-background-color: hsla(var(--ifabsh-color-info-h), var(--ifabsh-color-info-s), var(--ifabsh-color-info-l), var(--ifabsh-badge-soft-factor));
  --_badge-border-color: transparent;
}
.badge.-info.-soft.-outline {
  --_badge-border-color: var(--ifabsh-badge-border-color);
}

.badge.-success {
  --ifabsh-badge-background-color: var(--ifabsh-color-success, #15c182);
  --ifabsh-badge-border-color: var(--ifabsh-color-success, #15c182);
  --ifabsh-badge-color: var(--ifabsh-color-success-contrast, #fff);
}
.badge.-success.-soft {
  --ifabsh-badge-color: var(--ifabsh-badge-background-color);
  --_badge-background-color: hsla(var(--ifabsh-color-success-h), var(--ifabsh-color-success-s), var(--ifabsh-color-success-l), var(--ifabsh-badge-soft-factor));
  --_badge-border-color: transparent;
}
.badge.-success.-soft.-outline {
  --_badge-border-color: var(--ifabsh-badge-border-color);
}

.badge.-warning {
  --ifabsh-badge-background-color: var(--ifabsh-color-warning, #fca311);
  --ifabsh-badge-border-color: var(--ifabsh-color-warning, #fca311);
  --ifabsh-badge-color: var(--ifabsh-color-warning-contrast, #000);
}
.badge.-warning.-soft {
  --ifabsh-badge-color: var(--ifabsh-badge-background-color);
  --_badge-background-color: hsla(var(--ifabsh-color-warning-h), var(--ifabsh-color-warning-s), var(--ifabsh-color-warning-l), var(--ifabsh-badge-soft-factor));
  --_badge-border-color: transparent;
}
.badge.-warning.-soft.-outline {
  --_badge-border-color: var(--ifabsh-badge-border-color);
}

.badge.-danger {
  --ifabsh-badge-background-color: var(--ifabsh-color-danger, #fb3e4e);
  --ifabsh-badge-border-color: var(--ifabsh-color-danger, #fb3e4e);
  --ifabsh-badge-color: var(--ifabsh-color-danger-contrast, #fff);
}
.badge.-danger.-soft {
  --ifabsh-badge-color: var(--ifabsh-badge-background-color);
  --_badge-background-color: hsla(var(--ifabsh-color-danger-h), var(--ifabsh-color-danger-s), var(--ifabsh-color-danger-l), var(--ifabsh-badge-soft-factor));
  --_badge-border-color: transparent;
}
.badge.-danger.-soft.-outline {
  --_badge-border-color: var(--ifabsh-badge-border-color);
}

.badge.-outline:not(.-soft) {
  --_badge-background-color: transparent;
  --_badge-border-color: var(--ifabsh-badge-border-color);
}
.badge.-outline:not(.-soft):not(.-default) {
  --_badge-color: var(--ifabsh-badge-background-color);
}

.badge.-pill {
  --ifabsh-badge-border-radius: 10rem;
}

.badge.-circle {
  --ifabsh-badge-border-radius: 100%;
  height: var(--ifabsh-badge-circle-size);
  overflow: hidden;
  padding: 0;
  text-indent: 100%;
  width: var(--ifabsh-badge-circle-size);
}

@keyframes badge-pulse {
  0% {
    box-shadow: 0 0 0 0 var(--_badge-pulse-color);
  }
  70% {
    box-shadow: 0 0 0 var(--_badge-pulse-size) transparent;
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}
.badge.-pulse {
  --_badge-pulse-color: var(--ifabsh-badge-pulse-color, var(--ifabsh-badge-background-color));
  --_badge-pulse-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-badge-pulse-duration, 1.5s));
  --_badge-pulse-size: var(--ifabsh-badge-pulse-size, 0.5em);
  animation: badge-pulse var(--_badge-pulse-duration) infinite;
}

.badge-group {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: var(--ifabsh-badge-group-gap, var(--ifabsh-spacing-s, 0.5rem));
}
.badge-group > * {
  margin: 0;
}

.badge {
  --ifabsh-badge-font-size: var(--prins-font-size-xs);
}
.badge.-default {
  --ifabsh-badge-soft-factor: 0.5;
}
.badge.-soft.-danger {
  --_custom-l: calc(var(--prins-color-danger-l) - 13%);
  --ifabsh-badge-color: hsl(var(--prins-color-danger-h), var(--prins-color-danger-s), var(--_custom-l));
}
.badge.-soft.-info {
  --_custom-l: calc(var(--prins-color-info-l) - 13%);
  --ifabsh-badge-color: hsl(var(--prins-color-info-h), var(--prins-color-info-s), var(--_custom-l));
}
.badge.-soft.-success {
  --_custom-l: calc(var(--prins-color-success-l) - 13%);
  --ifabsh-badge-color: hsl(var(--prins-color-success-h), var(--prins-color-success-s), var(--_custom-l));
}
.badge.-soft.-warning {
  --_custom-l: calc(var(--prins-color-warning-l) - 13%);
  --ifabsh-badge-color: hsl(var(--prins-color-warning-h), var(--prins-color-warning-s), var(--_custom-l));
}
.badge.-secondary {
  --ifabsh-badge-background-color: var(--prins-color-secondary);
  --ifabsh-badge-border-color: var(--prins-color-secondary);
  --ifabsh-badge-color: var(--prins-color-secondary-contrast);
}
.badge.-secondary.-soft {
  --_badge-background-color: hsla(var(--prins-color-secondary-h), var(--prins-color-secondary-s), var(--prins-color-secondary-l), var(--ifabsh-badge-soft-factor));
  --_badge-color: var(--ifabsh-badge-background-color);
  --_badge-border-color: transparent;
}
.badge.-secondary.-soft.-outline {
  --_badge-border-color: var(--ifabsh-badge-border-color);
}

.icon-button > .badge {
  position: absolute;
  right: 0;
  top: 0;
  translate: 50% -50%;
}

.button {
  --ifabsh-button-background-color: var(--ifabsh-input-background-color, #fff);
  --ifabsh-button-border-color: var(--ifabsh-input-border-color, #e8e8e8);
  --ifabsh-button-border-width: var(--ifabsh-input-border-width, var(--ifabsh-border-width, 1px));
  --ifabsh-button-color: var(--ifabsh-input-color, #000);
  --ifabsh-button-duration: var(--ifabsh-duration, 0.15s);
  --ifabsh-button-font-size: var(--ifabsh-input-font-size, var(--ifabsh-font-size, 1rem));
  --ifabsh-button-height: var(--ifabsh-input-height, 40px);
  --ifabsh-button-line-height: calc(var(--ifabsh-button-height) - var(--ifabsh-button-border-width) * 2);
  --ifabsh-button-padding: var(--ifabsh-input-padding, var(--ifabsh-spacing, 1rem));
  --ifabsh-button-prefix-suffix-gap: calc(var(--ifabsh-button-font-size) / 2);
  --ifabsh-button-soft-factor: 0.1;
  --_btn-background-color: var(--ifabsh-button-background-color);
  --_btn-border-color: var(--ifabsh-button-border-color);
  --_btn-color: var(--ifabsh-button-color);
  --_btn-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-button-duration));
  align-items: stretch;
  background-color: var(--_btn-background-color);
  border: var(--ifabsh-button-border-width) solid var(--_btn-border-color);
  border-radius: var(--ifabsh-button-border-radius, var(--ifabsh-input-border-radius, var(--ifabsh-border-radius, 0px)));
  color: var(--_btn-color) !important; /* stylelint-disable-line declaration-no-important */
  display: inline-flex;
  font-size: var(--ifabsh-button-font-size);
  font-style: var(--ifabsh-button-font-style, normal);
  font-weight: var(--ifabsh-button-font-weight, normal);
  gap: var(--ifabsh-button-prefix-suffix-gap);
  height: var(--ifabsh-button-height);
  justify-content: center;
  line-height: var(--ifabsh-button-line-height);
  max-width: 100%;
  min-width: var(--ifabsh-button-height);
  overflow: hidden;
  padding: 0 var(--ifabsh-button-padding);
  text-decoration-line: none;
  text-transform: var(--ifabsh-button-text-transform, var(--ifabsh-input-text-transform, none));
  transition-duration: var(--_btn-duration);
  transition-property: background-color, border-color, color;
  transition-timing-function: ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
}
.button i,
.button .icon,
.button svg {
  pointer-events: none;
  position: relative;
}
.button__label {
  align-items: center;
  order: 2;
  overflow: hidden;
  pointer-events: none;
  text-overflow: ellipsis;
}
.button__prefix, .button__suffix, .button__caret {
  align-items: center;
  color: var(--ifabsh-button-prefix-suffix-color, currentColor);
  display: flex;
  flex: 0 0 auto;
}
.button__prefix {
  order: 1;
  pointer-events: none;
}
.button__suffix {
  order: 3;
  pointer-events: none;
}
.button__caret {
  order: 4;
  pointer-events: none;
}
@media (hover: hover) {
  .button:hover {
    --_btn-background-color: var(--ifabsh-button-background-color-hover, var(--ifabsh-button-background-color));
    --_btn-border-color: var(--ifabsh-button-border-color-hover, var(--ifabsh-button-border-color));
    --_btn-color: var(--ifabsh-button-color-hover, var(--ifabsh-button-color));
  }
  .button:hover:not(.-text) {
    text-decoration-line: none;
  }
}
.button:focus, .button._focus {
  --_btn-background-color: var(--ifabsh-button-background-color-focus, var(--ifabsh-button-background-color));
  --_btn-border-color: var(--ifabsh-button-border-color-focus, var(--ifabsh-button-border-color));
  --_btn-color: var(--ifabsh-button-color-focus, var(--ifabsh-button-color));
  box-shadow: none;
  outline: none;
}
.button:focus:not(.-text), .button._focus:not(.-text) {
  text-decoration-line: none;
}
.button._focus-visible:focus, .button:focus-visible:focus {
  outline: var(--_outline-color, var(--ifabsh-focus-outline-color)) var(--_outline-style, var(--ifabsh-focus-outline-style)) var(--_outline-width, var(--ifabsh-focus-outline-width));
  outline-offset: var(--_outline-offset, var(--ifabsh-focus-outline-offset));
}
.button:active, .button._active {
  --_btn-background-color: var(--ifabsh-button-background-color-active, var(--ifabsh-button-background-color));
  --_btn-border-color: var(--ifabsh-button-border-color-active, var(--ifabsh-button-border-color));
  --_btn-color: var(--ifabsh-button-color-active, var(--ifabsh-button-color));
}
.button:disabled, .button[disabled], .button._disabled {
  cursor: not-allowed !important; /* stylelint-disable-line declaration-no-important */
  opacity: var(--ifabsh-button-disabled-opacity, var(--ifabsh-input-disabled-opacity, var(--ifabsh-disabled-opacity, 0.4))) !important; /* stylelint-disable-line declaration-no-important */
}
.button:disabled *, .button[disabled] *, .button._disabled * {
  pointer-events: none;
}

.button.split-button:not(button) {
  border: 0 none;
  flex-wrap: nowrap;
  gap: 0;
  padding: 0;
}
.button.split-button:not(button) > .button:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
  border-right-width: 0;
  border-top-right-radius: 0;
}
.button.split-button:not(button) > .button + .button {
  border-bottom-left-radius: 0;
  border-left-width: 0;
  border-top-left-radius: 0;
  min-width: var(--ifabsh-button-split-width, var(--ifabsh-button-height));
  padding: 0;
  position: relative;
}
.button.split-button:not(button) > .button + .button::after {
  background-color: var(--ifabsh-button-split-color, currentColor);
  content: ""; /* stylelint-disable-line string-quotes */
  display: block;
  height: var(--ifabsh-button-split-height, var(--ifabsh-button-font-size));
  left: 0;
  opacity: var(--ifabsh-button-split-opacity, 0.3125);
  position: absolute;
  top: 51%;
  translate: 0 -50%;
  width: var(--ifabsh-button-border-width);
}

.button.-outline,
.button.-soft {
  --focus-outline-color: currentColor;
}

.button.-default {
  --ifabsh-button-background-color: var(--ifabsh-color-grey, #e8e8e8);
  --ifabsh-button-border-color: var(--ifabsh-color-grey, #e8e8e8);
  --ifabsh-button-color: var(--ifabsh-color-text, #3e3e3e);
}
.button.-default.-outline:not(.-soft) {
  --ifabsh-button-background-color: transparent;
}
.button.-default.-soft {
  --ifabsh-button-background-color: hsla(var(--ifabsh-color-grey-h), var(--ifabsh-color-grey-s), var(--ifabsh-color-grey-l), var(--ifabsh-button-soft-factor));
}
.button.-default.-soft:not(.-outline) {
  --ifabsh-button-border-color: transparent;
}

.button.-primary {
  --ifabsh-button-background-color: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-button-border-color: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-button-color: var(--ifabsh-color-ui-contrast, #fff);
}
.button.-primary.-outline, .button.-primary.-soft {
  --ifabsh-button-color: var(--ifabsh-color-ui);
}
.button.-primary.-outline:not(.-soft) {
  --ifabsh-button-background-color: transparent;
}
.button.-primary.-soft {
  --ifabsh-button-background-color: hsla(var(--ifabsh-color-ui-h), var(--ifabsh-color-ui-s), var(--ifabsh-color-ui-l), var(--ifabsh-button-soft-factor));
}
.button.-primary.-soft:not(.-outline) {
  --ifabsh-button-border-color: transparent;
}

.button.-info {
  --ifabsh-button-background-color: var(--ifabsh-color-info, #2db5cd);
  --ifabsh-button-border-color: var(--ifabsh-color-info, #2db5cd);
  --ifabsh-button-color: var(--ifabsh-color-info-contrast, #000);
}
.button.-info.-outline, .button.-info.-soft {
  --ifabsh-button-color: var(--ifabsh-color-info);
}
.button.-info.-outline:not(.-soft) {
  --ifabsh-button-background-color: transparent;
}
.button.-info.-soft {
  --ifabsh-button-background-color: hsla(var(--ifabsh-color-info-h), var(--ifabsh-color-info-s), var(--ifabsh-color-info-l), var(--ifabsh-button-soft-factor));
}
.button.-info.-soft:not(.-outline) {
  --ifabsh-button-border-color: transparent;
}

.button.-success {
  --ifabsh-button-background-color: var(--ifabsh-color-success, #15c182);
  --ifabsh-button-border-color: var(--ifabsh-color-success, #15c182);
  --ifabsh-button-color: var(--ifabsh-color-success-contrast, #fff);
}
.button.-success.-outline, .button.-success.-soft {
  --ifabsh-button-color: var(--ifabsh-color-success);
}
.button.-success.-outline:not(.-soft) {
  --ifabsh-button-background-color: transparent;
}
.button.-success.-soft {
  --ifabsh-button-background-color: hsla(var(--ifabsh-color-success-h), var(--ifabsh-color-success-s), var(--ifabsh-color-success-l), var(--ifabsh-button-soft-factor));
}
.button.-success.-soft:not(.-outline) {
  --ifabsh-button-border-color: transparent;
}

.button.-warning {
  --ifabsh-button-background-color: var(--ifabsh-color-warning, #fca311);
  --ifabsh-button-border-color: var(--ifabsh-color-warning, #fca311);
  --ifabsh-button-color: var(--ifabsh-color-warning-contrast, #000);
}
.button.-warning.-outline, .button.-warning.-soft {
  --ifabsh-button-color: var(--ifabsh-color-warning);
}
.button.-warning.-outline:not(.-soft) {
  --ifabsh-button-background-color: transparent;
}
.button.-warning.-soft {
  --ifabsh-button-background-color: hsla(var(--ifabsh-color-warning-h), var(--ifabsh-color-warning-s), var(--ifabsh-color-warning-l), var(--ifabsh-button-soft-factor));
}
.button.-warning.-soft:not(.-outline) {
  --ifabsh-button-border-color: transparent;
}

.button.-danger {
  --ifabsh-button-background-color: var(--ifabsh-color-danger, #fb3e4e);
  --ifabsh-button-border-color: var(--ifabsh-color-danger, #fb3e4e);
  --ifabsh-button-color: var(--ifabsh-color-danger-contrast, #fff);
}
.button.-danger.-outline, .button.-danger.-soft {
  --ifabsh-button-color: var(--ifabsh-color-danger);
}
.button.-danger.-outline:not(.-soft) {
  --ifabsh-button-background-color: transparent;
}
.button.-danger.-soft {
  --ifabsh-button-background-color: hsla(var(--ifabsh-color-danger-h), var(--ifabsh-color-danger-s), var(--ifabsh-color-danger-l), var(--ifabsh-button-soft-factor));
}
.button.-danger.-soft:not(.-outline) {
  --ifabsh-button-border-color: transparent;
}

.button.-l {
  --ifabsh-button-font-size: var(--ifabsh-input-font-size-l, var(--ifabsh-font-size-l, 1.25rem));
  --ifabsh-button-height: var(--ifabsh-input-height-l, 50px);
  --ifabsh-button-padding: var(--ifabsh-input-padding-l, var(--ifabsh-spacing-l, 1.25rem));
}

.button.-s {
  --ifabsh-button-font-size: var(--ifabsh-input-font-size-s, var(--ifabsh-font-size-s, 0.75rem));
  --ifabsh-button-height: var(--ifabsh-input-height-s, 30px);
  --ifabsh-button-padding: var(--ifabsh-input-padding-s, var(--ifabsh-spacing-s, 0.5rem));
}

.button.-pill {
  border-radius: var(--ifabsh-button-height);
}

.button.-circle {
  --ifabsh-button-padding: 0;
  border-radius: 50%;
  width: var(--ifabsh-button-height);
}
.button.-circle .button__prefix, .button.-circle .button__suffix {
  display: none;
}
.button.-text {
  --_btn-background-color: transparent;
  --_btn-border-color: transparent;
  --_btn-color: inherit;
}

.button[data-caret] {
  --_btn-caret-size: var(--ifabsh-button-caret-size, 1em);
}
.button[data-caret] .button__suffix {
  display: none;
}
.button[data-caret] .button__caret svg {
  height: var(--_btn-caret-size);
  width: var(--_btn-caret-size);
}

.button[data-loading] {
  cursor: wait;
  pointer-events: none;
  position: relative;
}
.button > .spinner {
  display: none;
}

.button[data-loading] > *:not(.spinner) {
  visibility: hidden;
}
.button[data-loading] > .spinner {
  --_sp-pos: calc(50% - var(--ifabsh-spinner-size) / 2);
  display: inline-block;
  left: var(--_sp-pos);
  position: absolute;
  top: var(--_sp-pos);
  z-index: 5;
}

.button:not(.-text) {
  --prins-link-text-decoration-line: none;
}
.button__suffix > .badge {
  --ifabsh-badge-padding-x: var(--ifabsh-spacing-xs);
  --ifabsh-badge-padding-y: var(--ifabsh-spacing-xxs);
}
a.button {
  text-decoration: none;
}

.button:not(.-default, .-primary, .-secondary, .-info, .-success, .-warning, .-danger) {
  --ifabsh-button-border-color-hover:
  	hsl(var(--prins-color-black-h), var(--prins-color-black-s), 70%);
}

.button.-default {
  --ifabsh-button-background-color-hover:
  	hsl(var(--ifabsh-color-grey-h), var(--ifabsh-color-grey-s), calc(var(--ifabsh-color-grey-l) - 3%));
  --ifabsh-button-border-color-hover: var(--ifabsh-button-background-color-hover);
  --ifabsh-button-soft-factor: 0.5;
}
.button.-default.-outline:not(.-soft) {
  --ifabsh-button-background-color-hover:
  	hsla(var(--ifabsh-color-grey-h), var(--ifabsh-color-grey-s), var(--ifabsh-color-grey-l), calc(var(--ifabsh-button-soft-factor) - 0.25));
  --ifabsh-button-border-color-hover:
  	hsl(var(--ifabsh-color-grey-h), var(--ifabsh-color-grey-s) calc(var(--ifabsh-color-grey-l) - 3%), 0%);
}
.button.-default.-soft:not(.-outline) {
  --ifabsh-button-background-color-hover:
  	hsla(var(--ifabsh-color-grey-h), var(--ifabsh-color-grey-s), var(--ifabsh-color-grey-l), calc(var(--ifabsh-button-soft-factor) + 0.2));
  --ifabsh-button-border-color-hover: transparent;
}

.button.-secondary {
  --ifabsh-button-background-color: var(--prins-color-secondary);
  --ifabsh-button-border-color: var(--prins-color-secondary);
  --ifabsh-button-color: var(--prins-color-secondary-contrast);
}
.button.-secondary.-outline, .button.-secondary.-soft {
  --ifabsh-button-color: var(--prins-color-secondary);
}
.button.-secondary.-outline:not(.-soft) {
  --ifabsh-button-background-color: transparent;
}
.button.-secondary.-soft {
  --ifabsh-button-background-color:
  	hsla(var(--prins-color-secondary-h), var(--prins-color-secondary-s), var(--prins-color-secondary-l), var(--ifabsh-button-soft-factor));
}
.button.-secondary.-soft:not(.-outline) {
  --ifabsh-button-border-color: transparent;
}

.button.-danger {
  --ifabsh-button-background-color-hover:
  	hsl(var(--prins-color-danger-h), var(--prins-color-danger-s), calc(var(--prins-color-danger-l) - 4%));
  --ifabsh-button-border-color-hover: var(--ifabsh-button-background-color-hover);
}
.button.-danger.-outline, .button.-danger.-soft {
  --_lighten: calc(var(--prins-color-danger-l) - 13%);
  --ifabsh-button-color:
  	hsl(var(--prins-color-danger-h), var(--prins-color-danger-s), var(--_lighten));
}
.button.-danger.-outline:not(.-soft) {
  --ifabsh-button-background-color-hover:
  	hsla(var(--prins-color-danger-h), var(--prins-color-danger-s), var(--prins-color-danger-l), var(--ifabsh-button-soft-factor));
  --ifabsh-button-border-color-hover: var(--prins-color-danger);
}
.button.-danger.-soft:not(.-outline) {
  --ifabsh-button-background-color-hover:
  	hsla(var(--prins-color-danger-h), var(--prins-color-danger-s), var(--prins-color-danger-l), calc(var(--ifabsh-button-soft-factor) + 0.1));
  --ifabsh-button-border-color-hover: transparent;
}

.button.-info {
  --ifabsh-button-background-color-hover:
  	hsl(var(--prins-color-info-h), var(--prins-color-info-s), calc(var(--prins-color-info-l) - 6%));
  --ifabsh-button-border-color-hover: var(--ifabsh-button-background-color-hover);
}
.button.-info.-outline, .button.-info.-soft {
  --_lighten: calc(var(--prins-color-info-l) - 13%);
  --ifabsh-button-color:
  	hsl(var(--prins-color-info-h), var(--prins-color-info-s), var(--_lighten));
}
.button.-info.-outline:not(.-soft) {
  --ifabsh-button-background-color-hover:
  	hsla(var(--prins-color-info-h), var(--prins-color-info-s), var(--prins-color-info-l), var(--ifabsh-button-soft-factor));
  --ifabsh-button-border-color-hover: var(--prins-color-info);
}
.button.-info.-soft:not(.-outline) {
  --ifabsh-button-background-color-hover:
  	hsla(var(--prins-color-info-h), var(--prins-color-info-s), var(--prins-color-info-l), calc(var(--ifabsh-button-soft-factor) + 0.1));
  --ifabsh-button-border-color-hover: transparent;
}

.button.-success {
  --ifabsh-button-background-color-hover:
  	hsl(var(--prins-color-success-h), var(--prins-color-success-s), calc(var(--prins-color-success-l) - 4%));
  --ifabsh-button-border-color-hover: var(--ifabsh-button-background-color-hover);
}
.button.-success.-outline, .button.-success.-soft {
  --_lighten: calc(var(--prins-color-success-l) - 13%);
  --ifabsh-button-color:
  	hsl(var(--prins-color-success-h), var(--prins-color-success-s), var(--_lighten));
}
.button.-success.-outline:not(.-soft) {
  --ifabsh-button-background-color-hover:
  	hsla(var(--prins-color-success-h), var(--prins-color-success-s), var(--prins-color-success-l), var(--ifabsh-button-soft-factor));
  --ifabsh-button-border-color-hover: var(--prins-color-success);
}
.button.-success.-soft:not(.-outline) {
  --ifabsh-button-background-color-hover:
  	hsla(var(--prins-color-success-h), var(--prins-color-success-s), var(--prins-color-success-l), calc(var(--ifabsh-button-soft-factor) + 0.1));
  --ifabsh-button-border-color-hover: transparent;
}

.button.-warning {
  --ifabsh-button-background-color-hover:
  	hsl(var(--prins-color-warning-h), var(--prins-color-warning-s), calc(var(--prins-color-warning-l) - 5%));
  --ifabsh-button-border-color-hover: var(--ifabsh-button-background-color-hover);
}
.button.-warning.-outline, .button.-warning.-soft {
  --_lighten: calc(var(--prins-color-warning-l) - 13%);
  --ifabsh-button-color:
  	hsl(var(--prins-color-warning-h), var(--prins-color-warning-s), var(--_lighten));
}
.button.-warning.-outline:not(.-soft) {
  --ifabsh-button-background-color-hover:
  	hsla(var(--prins-color-warning-h), var(--prins-color-warning-s), var(--prins-color-warning-l), var(--ifabsh-button-soft-factor));
  --ifabsh-button-border-color-hover: var(--prins-color-warning);
}
.button.-warning.-soft:not(.-outline) {
  --ifabsh-button-background-color-hover:
  	hsla(var(--prins-color-warning-h), var(--prins-color-warning-s), var(--prins-color-warning-l), calc(var(--ifabsh-button-soft-factor) + 0.1));
  --ifabsh-button-border-color-hover: transparent;
}

.button.-primary {
  --ifabsh-button-background-color-hover:
  	hsl(var(--prins-color-primary-h), var(--prins-color-primary-s), calc(var(--prins-color-primary-l) - 8%));
  --ifabsh-button-border-color-hover: var(--ifabsh-button-background-color-hover);
}
.button.-primary.-outline, .button.-primary.-soft {
  --_lighten: calc(var(--prins-color-primary-l) - 13%);
  --ifabsh-button-color:
  	hsl(var(--prins-color-primary-h), var(--prins-color-primary-s), var(--_lighten));
}
.button.-primary.-outline:not(.-soft) {
  --ifabsh-button-background-color-hover:
  	hsla(var(--prins-color-primary-h), var(--prins-color-primary-s), var(--prins-color-primary-l), var(--ifabsh-button-soft-factor));
  --ifabsh-button-border-color-hover: var(--prins-color-primary);
}
.button.-primary.-soft:not(.-outline) {
  --ifabsh-button-background-color-hover:
  	hsla(var(--prins-color-primary-h), var(--prins-color-primary-s), var(--prins-color-primary-l), calc(var(--ifabsh-button-soft-factor) + 0.1));
  --ifabsh-button-border-color-hover: transparent;
}

.button.-secondary {
  --ifabsh-button-background-color-hover:
  	hsl(var(--prins-color-secondary-h), var(--prins-color-secondary-s), calc(var(--prins-color-secondary-l) - 6%));
  --ifabsh-button-border-color-hover: var(--ifabsh-button-background-color-hover);
}
.button.-secondary.-outline, .button.-secondary.-soft {
  --_lighten: calc(var(--prins-color-secondary-l) - 0%);
  --ifabsh-button-color:
  	hsl(var(--prins-color-secondary-h), var(--prins-color-secondary-s), var(--_lighten));
}
.button.-secondary.-outline:not(.-soft) {
  --ifabsh-button-background-color-hover:
  	hsla(var(--prins-color-secondary-h), var(--prins-color-secondary-s), var(--prins-color-secondary-l), var(--ifabsh-button-soft-factor));
  --ifabsh-button-border-color-hover: var(--prins-color-secondary);
}
.button.-secondary.-soft:not(.-outline) {
  --ifabsh-button-background-color-hover:
  	hsla(var(--prins-color-secondary-h), var(--prins-color-secondary-s), var(--prins-color-secondary-l), calc(var(--ifabsh-button-soft-factor) + 0.1));
  --ifabsh-button-border-color-hover: transparent;
}

.icon-button {
  --ifabsh-icon-button-background-color: transparent;
  --ifabsh-icon-button-background-color-hover: var(--ifabsh-icon-button-background-color);
  --ifabsh-icon-button-background-color-active: var(--ifabsh-icon-button-background-color-hover);
  --ifabsh-icon-button-border-color: transparent;
  --ifabsh-icon-button-border-width: var(--ifabsh-input-border-width, var(--ifabsh-border-width, 1px));
  --ifabsh-icon-button-color: inherit;
  --ifabsh-icon-button-color-hover: var(--ifabsh-icon-button-color);
  --ifabsh-icon-button-color-active: var(--ifabsh-icon-button-color-hover);
  --ifabsh-icon-button-duration: var(--ifabsh-duration, 0.15s);
  --ifabsh-icon-button-height: var(--ifabsh-input-height, 40px);
  --ifabsh-icon-button-line-height: calc(var(--ifabsh-icon-button-height) - var(--ifabsh-icon-button-border-width) * 2);
  --ifabsh-icon-button-padding: 0px; /* stylelint-disable-line length-zero-no-unit */
  --ifabsh-icon-button-soft-factor: 0.1;
  --_ibtn-background-color: var(--ifabsh-icon-button-background-color);
  --_ibtn-color: var(--ifabsh-icon-button-color);
  --_ibtn-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-icon-button-duration));
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--_ibtn-background-color);
  border: none;
  border-radius: var(--ifabsh-icon-button-border-radius, var(--ifabsh-input-border-radius, var(--ifabsh-border-radius, 0px)));
  color: var(--_ibtn-color) !important; /* stylelint-disable-line declaration-no-important */
  display: inline-flex;
  flex: 0 0 auto;
  font-size: var(--ifabsh-icon-button-font-size, inherit);
  height: var(--ifabsh-icon-button-height);
  justify-content: center;
  line-height: var(--ifabsh-icon-button-line-height);
  min-width: var(--ifabsh-icon-button-height);
  padding: 0 var(--ifabsh-icon-button-padding);
  position: relative;
  text-decoration-line: none;
  transition-duration: var(--_ibtn-duration);
  transition-property: background-color, border-color, color, opacity;
  transition-timing-function: ease-in-out;
}
.icon-button__icon {
  align-items: center;
  display: flex;
  height: 1em;
  justify-content: center;
  width: 1em;
}
@media (hover: hover) {
  .icon-button:hover {
    --_ibtn-background-color: var(--ifabsh-icon-button-background-color-hover);
    --_ibtn-color: var(--ifabsh-icon-button-color-hover);
  }
}
.icon-button:focus, .icon-button._focus {
  --_ibtn-background-color: var(--ifabsh-icon-button-background-color-focus, var(--ifabsh-icon-button-background-color));
  --_ibtn-color: var(--ifabsh-icon-button-color-focus, var(--ifabsh-icon-button-color));
  box-shadow: none;
  outline: none;
}
.icon-button._focus-visible:focus, .icon-button:focus-visible:focus {
  outline: var(--_outline-color, var(--ifabsh-focus-outline-color)) var(--_outline-style, var(--ifabsh-focus-outline-style)) var(--_outline-width, var(--ifabsh-focus-outline-width));
  outline-offset: var(--_outline-offset, var(--ifabsh-focus-outline-offset));
}
.icon-button:active, .icon-button._active {
  --_ibtn-background-color: var(--ifabsh-icon-button-background-color-active);
  --_ibtn-color: var(--ifabsh-icon-button-color-active);
}
.icon-button:disabled, .icon-button[disabled], .icon-button._disabled {
  box-shadow: none;
  cursor: not-allowed;
  opacity: var(--ifabsh-icon-button-disabled-opacity, 0.4);
  outline: none;
  pointer-events: none;
}

.icon-button.-default {
  --ifabsh-icon-button-color: var(--ifabsh-color-text, #3e3e3e);
}
.icon-button.-default.-fill:not(.-soft) {
  --ifabsh-icon-button-background-color: var(--ifabsh-color-grey, #e8e8e8);
  --focus-outline-color: currentColor;
}
.icon-button.-default.-soft:not(.-fill) {
  --ifabsh-icon-button-background-color: hsla(var(--ifabsh-color-grey-h), var(--ifabsh-color-grey-s), var(--ifabsh-color-grey-l), var(--ifabsh-icon-button-soft-factor));
}

.icon-button.-primary {
  --ifabsh-icon-button-color: var(--ifabsh-color-ui, #6176ff);
}
.icon-button.-primary.-fill:not(.-soft) {
  --ifabsh-icon-button-background-color: var(--ifabsh-color-ui, #6176ff);
  --ifabsh-icon-button-color: var(--ifabsh-color-ui-contrast, #fff);
}
.icon-button.-primary.-soft:not(.-fill) {
  --ifabsh-icon-button-background-color: hsla(var(--ifabsh-color-ui-h), var(--ifabsh-color-ui-s), var(--ifabsh-color-ui-l), var(--ifabsh-icon-button-soft-factor));
  --ifabsh-icon-button-color: var(--ifabsh-color-ui);
}

.icon-button.-info {
  --ifabsh-icon-button-color: var(--ifabsh-color-info, #2db5cd);
}
.icon-button.-info.-fill:not(.-soft) {
  --ifabsh-icon-button-background-color: var(--ifabsh-color-info, #2db5cd);
  --ifabsh-icon-button-color: var(--ifabsh-color-info-contrast, #000);
}
.icon-button.-info.-soft:not(.-fill) {
  --ifabsh-icon-button-background-color: hsla(var(--ifabsh-color-info-h), var(--ifabsh-color-info-s), var(--ifabsh-color-info-l), var(--ifabsh-icon-button-soft-factor));
  --ifabsh-icon-button-color: var(--ifabsh-color-info);
}

.icon-button.-success {
  --ifabsh-icon-button-color: var(--ifabsh-color-success, #15c182);
}
.icon-button.-success.-fill:not(.-soft) {
  --ifabsh-icon-button-background-color: var(--ifabsh-color-success, #15c182);
  --ifabsh-icon-button-color: var(--ifabsh-color-success-contrast, #fff);
}
.icon-button.-success.-soft:not(.-fill) {
  --ifabsh-icon-button-background-color: hsla(var(--ifabsh-color-success-h), var(--ifabsh-color-success-s), var(--ifabsh-color-success-l), var(--ifabsh-icon-button-soft-factor));
  --ifabsh-icon-button-color: var(--ifabsh-color-success);
}

.icon-button.-warning {
  --ifabsh-icon-button-color: var(--ifabsh-color-warning, #fca311);
}
.icon-button.-warning.-fill:not(.-soft) {
  --ifabsh-icon-button-background-color: var(--ifabsh-color-warning, #fca311);
  --ifabsh-icon-button-color: var(--ifabsh-color-warning-contrast, #000);
}
.icon-button.-warning.-soft:not(.-fill) {
  --ifabsh-icon-button-background-color: hsla(var(--ifabsh-color-warning-h), var(--ifabsh-color-warning-s), var(--ifabsh-color-warning-l), var(--ifabsh-icon-button-soft-factor));
  --ifabsh-icon-button-color: var(--ifabsh-color-warning);
}

.icon-button.-danger {
  --ifabsh-icon-button-color: var(--ifabsh-color-danger, #fb3e4e);
}
.icon-button.-danger.-fill:not(.-soft) {
  --ifabsh-icon-button-background-color: var(--ifabsh-color-danger, #fb3e4e);
  --ifabsh-icon-button-color: var(--ifabsh-color-danger-contrast, #fff);
}
.icon-button.-danger.-soft:not(.-fill) {
  --ifabsh-icon-button-background-color: hsla(var(--ifabsh-color-danger-h), var(--ifabsh-color-danger-s), var(--ifabsh-color-danger-l), var(--ifabsh-icon-button-soft-factor));
  --ifabsh-icon-button-color: var(--ifabsh-color-danger);
}

.icon-button.-l {
  --ifabsh-icon-button-font-size: var(--ifabsh-input-font-size-l, var(--ifabsh-font-size-l, 1.25rem));
  --ifabsh-icon-button-height: var(--ifabsh-input-height-l, 50px);
}

.icon-button.-s {
  --ifabsh-icon-button-font-size: var(--ifabsh-input-font-size-s, var(--ifabsh-font-size-s, 0.75rem));
  --ifabsh-icon-button-height: var(--ifabsh-input-height-s, 30px);
}

.icon-button.-pill {
  border-radius: var(--ifabsh-icon-button-height);
}

.icon-button[data-is-loading] {
  cursor: wait;
  pointer-events: none;
}
.icon-button > .spinner {
  display: none;
}

.icon-button[data-is-loading] .icon-button__icon {
  visibility: hidden;
}
.icon-button[data-is-loading] > .spinner {
  --_sp-pos: calc(50% - var(--ifabsh-spinner-size) / 2);
  display: inline-block;
  left: var(--_sp-pos);
  position: absolute;
  top: var(--_sp-pos);
  z-index: 5;
}

.icon-button.-default {
  --ifabsh-icon-button-soft-factor: 0.5;
  --ifabsh-icon-button-background-color-hover:
  	hsl(var(--ifabsh-color-grey-h), var(--ifabsh-color-grey-s), calc(var(--ifabsh-color-grey-l) - 3%));
}
.icon-button.-default.-soft:not(.-fill) {
  --ifabsh-icon-button-background-color-hover:
  	hsla(var(--ifabsh-color-grey-h), var(--ifabsh-color-grey-s), var(--ifabsh-color-grey-l), calc(var(--ifabsh-icon-button-soft-factor) + 0.2));
}

.icon-button.-secondary {
  --ifabsh-icon-button-color: var(--prins-color-secondary);
}
.icon-button.-secondary.-fill:not(.-soft) {
  --ifabsh-icon-button-background-color: var(--prins-color-secondary);
  --ifabsh-icon-button-color: var(--prins-color-secondary-contrast);
  --focus-outline-color: var(--ifabsh-icon-button-background-color);
}
.icon-button.-secondary.-soft:not(.-fill) {
  --ifabsh-icon-button-background-color:
  	hsla(var(--prins-color-secondary-h), var(--prins-color-secondary-s), var(--prins-color-secondary-l), var(--ifabsh-icon-button-soft-factor));
  --ifabsh-icon-button-color: var(--ifabsh-color-secondary);
}

.icon-button.-danger {
  --ifabsh-icon-button-background-color-hover:
  	hsl(var(--prins-color-danger-h), var(--prins-color-danger-s), calc(var(--prins-color-danger-l) - 4%));
  --ifabsh-icon-button-color-hover: var(--prins-color-danger-contrast);
}
.icon-button.-danger.-soft:not(.-fill) {
  --_lighten: calc(var(--prins-color-danger-l) - 13%);
  --ifabsh-icon-button-color:
  	hsl(var(--prins-color-danger-h), var(--prins-color-danger-s), var(--_lighten));
  --ifabsh-icon-button-background-color-hover:
  	hsla(var(--prins-color-danger-h), var(--prins-color-danger-s), var(--prins-color-danger-l), calc(var(--ifabsh-icon-button-soft-factor) + 0.1));
  --ifabsh-icon-button-color-hover: var(--ifabsh-icon-button-color);
}

.icon-button.-info {
  --ifabsh-icon-button-background-color-hover:
  	hsl(var(--prins-color-info-h), var(--prins-color-info-s), calc(var(--prins-color-info-l) - 6%));
  --ifabsh-icon-button-color-hover: var(--prins-color-info-contrast);
}
.icon-button.-info.-soft:not(.-fill) {
  --_lighten: calc(var(--prins-color-info-l) - 13%);
  --ifabsh-icon-button-color:
  	hsl(var(--prins-color-info-h), var(--prins-color-info-s), var(--_lighten));
  --ifabsh-icon-button-background-color-hover:
  	hsla(var(--prins-color-info-h), var(--prins-color-info-s), var(--prins-color-info-l), calc(var(--ifabsh-icon-button-soft-factor) + 0.1));
  --ifabsh-icon-button-color-hover: var(--ifabsh-icon-button-color);
}

.icon-button.-success {
  --ifabsh-icon-button-background-color-hover:
  	hsl(var(--prins-color-success-h), var(--prins-color-success-s), calc(var(--prins-color-success-l) - 4%));
  --ifabsh-icon-button-color-hover: var(--prins-color-success-contrast);
}
.icon-button.-success.-soft:not(.-fill) {
  --_lighten: calc(var(--prins-color-success-l) - 13%);
  --ifabsh-icon-button-color:
  	hsl(var(--prins-color-success-h), var(--prins-color-success-s), var(--_lighten));
  --ifabsh-icon-button-background-color-hover:
  	hsla(var(--prins-color-success-h), var(--prins-color-success-s), var(--prins-color-success-l), calc(var(--ifabsh-icon-button-soft-factor) + 0.1));
  --ifabsh-icon-button-color-hover: var(--ifabsh-icon-button-color);
}

.icon-button.-warning {
  --ifabsh-icon-button-background-color-hover:
  	hsl(var(--prins-color-warning-h), var(--prins-color-warning-s), calc(var(--prins-color-warning-l) - 5%));
  --ifabsh-icon-button-color-hover: var(--prins-color-warning-contrast);
}
.icon-button.-warning.-soft:not(.-fill) {
  --_lighten: calc(var(--prins-color-warning-l) - 13%);
  --ifabsh-icon-button-color:
  	hsl(var(--prins-color-warning-h), var(--prins-color-warning-s), var(--_lighten));
  --ifabsh-icon-button-background-color-hover:
  	hsla(var(--prins-color-warning-h), var(--prins-color-warning-s), var(--prins-color-warning-l), calc(var(--ifabsh-icon-button-soft-factor) + 0.1));
  --ifabsh-icon-button-color-hover: var(--ifabsh-icon-button-color);
}

.icon-button.-primary {
  --ifabsh-icon-button-background-color-hover:
  	hsl(var(--prins-color-primary-h), var(--prins-color-primary-s), calc(var(--prins-color-primary-l) - 8%));
  --ifabsh-icon-button-color-hover: var(--prins-color-primary-contrast);
}
.icon-button.-primary.-soft:not(.-fill) {
  --_lighten: calc(var(--prins-color-primary-l) - 13%);
  --ifabsh-icon-button-color:
  	hsl(var(--prins-color-primary-h), var(--prins-color-primary-s), var(--_lighten));
  --ifabsh-icon-button-background-color-hover:
  	hsla(var(--prins-color-primary-h), var(--prins-color-primary-s), var(--prins-color-primary-l), calc(var(--ifabsh-icon-button-soft-factor) + 0.1));
  --ifabsh-icon-button-color-hover: var(--ifabsh-icon-button-color);
}

.icon-button.-secondary {
  --ifabsh-icon-button-background-color-hover:
  	hsl(var(--prins-color-secondary-h), var(--prins-color-secondary-s), calc(var(--prins-color-secondary-l) - 6%));
  --ifabsh-icon-button-color-hover: var(--prins-color-secondary-contrast);
}
.icon-button.-secondary.-soft:not(.-fill) {
  --_lighten: calc(var(--prins-color-secondary-l) - 0%);
  --ifabsh-icon-button-color:
  	hsl(var(--prins-color-secondary-h), var(--prins-color-secondary-s), var(--_lighten));
  --ifabsh-icon-button-background-color-hover:
  	hsla(var(--prins-color-secondary-h), var(--prins-color-secondary-s), var(--prins-color-secondary-l), calc(var(--ifabsh-icon-button-soft-factor) + 0.1));
  --ifabsh-icon-button-color-hover: var(--ifabsh-icon-button-color);
}

.card {
  --ifabsh-card-border-color: var(--ifabsh-color-border, #e8e8e8);
  --ifabsh-card-border-radius: var(--ifabsh-border-radius, 0px);
  --ifabsh-card-border-width: var(--ifabsh-border-width, 1px);
  --ifabsh-card-padding: var(--ifabsh-spacing, 1rem);
  --ifabsh-card-duration: var(--ifabsh-duration, 0.15s);
  --_cd-duration: calc(var(--global-duration-multiplier, 1) * var(--ifabsh-card-duration));
  background-color: var(--ifabsh-card-background-color, transparent);
  border: var(--ifabsh-card-border-width) solid var(--ifabsh-card-border-color);
  border-radius: var(--ifabsh-card-border-radius);
  color: inherit;
  display: flex;
  flex-direction: column;
  transition-duration: var(--_cd-duration);
  transition-property: border-color, box-shadow;
  transition-timing-function: ease-in-out;
}
.card .card-title { /* stylelint-disable-line string-quotes */
  color: var(--ifabsh-card-title-color, currentColor);
  -webkit-hyphens: manual;
  hyphens: manual;
  margin: 0;
  overflow-wrap: break-word;
}
.card__media {
  aspect-ratio: var(--ifabsh-card-media-aspect-ratio, auto);
  border-top-left-radius: var(--ifabsh-card-border-radius);
  border-top-right-radius: var(--ifabsh-card-border-radius);
  margin: calc(var(--ifabsh-card-border-width) * -1);
  overflow: hidden;
}
.card__media picture {
  aspect-ratio: var(--ifabsh-card-media-aspect-ratio);
}
.card__media .figure__caption {
  display: none;
}
.card__media > * {
  display: block;
  margin: 0;
  width: 100%;
}
.card__header, .card__body, .card__footer {
  transition-duration: var(--_cd-duration);
  transition-property: border-color;
  transition-timing-function: ease-in-out;
}
.card__header, .card__footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.card__header {
  border-bottom: var(--ifabsh-card-border-width) solid var(--ifabsh-card-border-color);
  flex-wrap: wrap;
  gap: calc(var(--ifabsh-card-padding) / 2);
  padding: var(--ifabsh-card-padding);
}
.card__header .card-title {
  width: auto;
}
.card__header > :nth-child(2) {
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
}
.card__header:first-child:last-child {
  border-bottom: 0 none;
}
.card__body {
  flex-grow: 1;
  padding: var(--ifabsh-card-padding);
}
.card__body > :first-child:not(.row) {
  margin-top: 0;
}
.card__body > :last-child:not(.row) {
  margin-bottom: 0;
}
.card__body + .card__body {
  border-top: var(--ifabsh-card-border-width) solid var(--ifabsh-card-border-color);
}
.card__footer {
  border-top: var(--ifabsh-card-border-width) solid var(--ifabsh-card-border-color);
  padding: var(--ifabsh-card-padding);
}

.card {
  --ifabsh-card-background-color: var(--prins-color-white);
  --ifabsh-card-title-color: var(--prins-base-text-color);
  border: 0 none;
  border-color: var(--ifabsh-card-background-color);
  box-shadow: var(--prins-box-shadow-size) var(--prins-box-shadow-color);
  color: inherit;
}
.card:hover, .card:focus-within {
  box-shadow: var(--prins-box-shadow-size-event) var(--prins-box-shadow-color);
}
.card .card-title {
  font-size: var(--prins-font-size-xl);
  font-weight: var(--prins-font-weight-semibold);
  line-height: var(--prins-base-line-height);
}
.card .card-controls {
  gap: calc(var(--ifabsh-card-padding) / 2);
}
.card .card-controls:first-child:last-child {
  margin-left: auto;
}
.card .-ignore-card-padding {
  --_offset: calc(-1 * var(--ifabsh-card-padding));
  margin-left: var(--_offset);
  margin-right: var(--_offset);
}
.card .-ignore-card-padding:first-child {
  margin-top: var(--_offset);
}
.card .-ignore-card-padding:last-child {
  margin-bottom: var(--_offset);
}
.col > .card + .card {
  margin-top: var(--prins-grid-gap-y);
}

.card[data-loading] {
  cursor: wait;
  pointer-events: none;
  position: relative;
}
.card[data-loading] > .loading-backdrop {
  backdrop-filter: var(--prins-backdrop-filter);
  background-color: var(--ifabsh-card-background-color);
  height: 100%;
  left: 0;
  opacity: var(--prins-backdrop-opacity);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}
.card[data-loading] > .spinner {
  --_sp-pos: calc(50% - var(--ifabsh-spinner-size) / 2);
  display: inline-block;
  left: var(--_sp-pos);
  position: absolute;
  top: var(--_sp-pos);
  z-index: 11;
}

.card-choose {
  --ifabsh-card-coose-icon-size: 3.5rem;
  text-align: center;
}
.card-choose .card__body > .icon {
  background-color: var(--ifabsh-card-coose-icon-background-color, var(--ifabsh-color-grey-light));
  color: var(--ifabsh-card-coose-icon-color, var(--prins-color-grey-light));
  font-size: var(--ifabsh-card-coose-icon-font-size, var(--prins-font-size-xxl));
  height: var(--ifabsh-card-coose-icon-size);
  line-height: var(--ifabsh-card-coose-icon-size);
  width: var(--ifabsh-card-coose-icon-size);
}
.card-choose .card__body > .icon + * {
  margin-top: calc(var(--ifabsh-card-padding) / 2);
}
.card-choose .card__body .card-title + p {
  margin-top: calc(var(--ifabsh-card-padding) / 2);
}
.card-choose .card__body p {
  color: var(--prins-muted-text-color);
  font-size: var(--prins-font-size-s);
}
.card-choose .card__footer {
  border-top: 0 none;
  flex-wrap: wrap;
  gap: var(--prins-spacing-s);
  justify-content: center;
  padding-top: 0;
}
.card-choose.-danger {
  --ifabsh-card-coose-icon-background-color:
  	hsla(var(--prins-color-danger-h), var(--prins-color-danger-s), var(--prins-color-danger-l), 0.1);
  --ifabsh-card-coose-icon-color: var(--prins-color-danger);
}
.card-choose.-info {
  --ifabsh-card-coose-icon-background-color:
  	hsla(var(--prins-color-info-h), var(--prins-color-info-s), var(--prins-color-info-l), 0.1);
  --ifabsh-card-coose-icon-color: var(--prins-color-info);
}
.card-choose.-success {
  --ifabsh-card-coose-icon-background-color:
  	hsla(var(--prins-color-success-h), var(--prins-color-success-s), var(--prins-color-success-l), 0.1);
  --ifabsh-card-coose-icon-color: var(--prins-color-success);
}
.card-choose.-warning {
  --ifabsh-card-coose-icon-background-color:
  	hsla(var(--prins-color-warning-h), var(--prins-color-warning-s), var(--prins-color-warning-l), 0.1);
  --ifabsh-card-coose-icon-color: var(--prins-color-warning);
}
.card-choose.-primary {
  --ifabsh-card-coose-icon-background-color:
  	hsla(var(--prins-color-primary-h), var(--prins-color-primary-s), var(--prins-color-primary-l), 0.1);
  --ifabsh-card-coose-icon-color: var(--prins-color-primary);
}
.card-choose.-secondary {
  --ifabsh-card-coose-icon-background-color:
  	hsla(var(--prins-color-secondary-h), var(--prins-color-secondary-s), var(--prins-color-secondary-l), 0.1);
  --ifabsh-card-coose-icon-color: var(--prins-color-secondary);
}

@keyframes key-visual-drop {
  0% {
    clip-path: circle(var(--_size-circle) at 50% calc(0% - var(--_size-circle)));
  }
  100% {
    clip-path: circle(calc(var(--_size-circle) * 2) at 50% calc(100% - var(--_size-circle)));
  }
}
@keyframes key-visual-grow {
  0% {
    clip-path: ellipse(var(--_size-circle) var(--_size-circle) at 50% calc(100% - var(--_size-circle)));
  }
  100% {
    clip-path: ellipse(85% 100% at 50% 0%);
  }
}
.key-visual {
  --prins-key-visual-overlay-color-a1: hsla(var(--prins-color-info-h), var(--prins-color-info-s), var(--prins-color-info-l), 1);
  --prins-key-visual-overlay-color-a2: hsla(var(--prins-color-info-h), var(--prins-color-info-s), var(--prins-color-info-l), 0);
  --prins-key-visual-overlay-color-b1: hsla(var(--prins-color-success-h), var(--prins-color-success-s), var(--prins-color-success-l), 1);
  --prins-key-visual-overlay-color-b2: hsla(var(--prins-color-success-h), var(--prins-color-success-s), var(--prins-color-success-l), 0);
  --prins-key-visual-overlay-color-c1: hsla(var(--prins-color-primary-h), var(--prins-color-primary-s), var(--prins-color-primary-l), 1);
  --prins-key-visual-overlay-color-c2: hsla(var(--prins-color-primary-h), var(--prins-color-primary-s), var(--prins-color-primary-l), 0);
  --_delay-1: calc(var(--_landing-duration-multiplier, 1) * 0.5s);
  --_duration-1: calc(var(--_landing-duration-multiplier, 1) * 0.25s);
  --_delay-2: calc(var(--_landing-duration-multiplier, 1) * 0.75s);
  --_duration-2: calc(var(--_landing-duration-multiplier, 1) * 0.5s);
  --_size-circle: 0.5rem;
  background-color: var(--prins-key-visual-background-color, var(--prins-color-primary-dark));
  clip-path: circle(var(--_size-circle) at 50% calc(0% - var(--_size-circle)));
  display: block;
}
.key-visual-media {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}
.key-visual-media::after {
  backdrop-filter: grayscale(0.8);
  background-image: linear-gradient(217deg, var(--prins-key-visual-overlay-color-a1), var(--prins-key-visual-overlay-color-a2) 55%), linear-gradient(127deg, var(--prins-key-visual-overlay-color-b1), var(--prins-key-visual-overlay-color-b2) 55%), linear-gradient(0deg, var(--prins-key-visual-overlay-color-c1), var(--prins-key-visual-overlay-color-c2) 70%);
  content: ""; /* stylelint-disable-line string-quotes */
  display: block;
  height: 100%;
  width: 100%;
}

[data-preloader=disabled] .key-visual { /* stylelint-disable-line string-quotes */
  animation: var(--_duration-1) ease-in var(--_delay-1) 1 both key-visual-drop, var(--_duration-2) ease-in var(--_delay-2) 1 forwards key-visual-grow;
}

@font-face {
  font-weight: 400;
  font-display: swap;
  font-family: Inter; /* stylelint-disable-line string-quotes */
  src: url('../../../media/fonts/inter/inter-regular.woff2') format('woff2'), url('../../../media/fonts/inter/inter-regular.woff') format('woff');
}
@font-face {
  font-weight: 600;
  font-display: swap;
  font-family: Inter; /* stylelint-disable-line string-quotes */
  src: url('../../../media/fonts/inter/inter-semi-bold.woff2') format('woff2'), url('../../../media/fonts/inter/inter-semi-bold.woff') format('woff');
}
@font-face {
  font-weight: 700;
  font-display: swap;
  font-family: Inter; /* stylelint-disable-line string-quotes */
  src: url('../../../media/fonts/inter/inter-bold.woff2') format('woff2'), url('../../../media/fonts/inter/inter-bold.woff') format('woff');
}
:root {
  --global-rootvar-prefix: prins-;
  --global-duration-multiplier: 1;
  --global-scroll-top-offset: 0px; /* stylelint-disable-line length-zero-no-unit */
  --prins-color-black-h: 0;
  --prins-color-black-s: 0%;
  --prins-color-black-l: 0%;
  --prins-color-black: hsl(var(--prins-color-black-h), var(--prins-color-black-s), var(--prins-color-black-l));
  --prins-color-grey-h: 208;
  --prins-color-grey-s: 9%;
  --prins-color-grey-l: 31%;
  --prins-color-grey: hsl(var(--prins-color-grey-h), var(--prins-color-grey-s), var(--prins-color-grey-l));
  --prins-color-grey-light3-h: var(--prins-color-grey-h);
  --prins-color-grey-light3-s: var(--prins-color-grey-s);
  --prins-color-grey-light3-l: 96%;
  --prins-color-grey-light3: hsl(var(--prins-color-grey-light3-h), var(--prins-color-grey-light3-s), var(--prins-color-grey-light3-l));
  --prins-color-grey-light2-h: var(--prins-color-grey-h);
  --prins-color-grey-light2-s: var(--prins-color-grey-s);
  --prins-color-grey-light2-l: 86%;
  --prins-color-grey-light2: hsl(var(--prins-color-grey-light2-h), var(--prins-color-grey-light2-s), var(--prins-color-grey-light2-l));
  --prins-color-grey-light-h: var(--prins-color-grey-h);
  --prins-color-grey-light-s: var(--prins-color-grey-s);
  --prins-color-grey-light-l: 56%;
  --prins-color-grey-light: hsl(var(--prins-color-grey-light-h), var(--prins-color-grey-light-s), var(--prins-color-grey-light-l));
  --prins-color-grey-dark-h: var(--prins-color-grey-h);
  --prins-color-grey-dark-s: var(--prins-color-grey-s);
  --prins-color-grey-dark-l: 50%;
  --prins-color-grey-dark: hsl(var(--prins-color-grey-dark-h), var(--prins-color-grey-dark-s), var(--prins-color-grey-dark-l));
  --prins-color-grey-dark2-h: var(--prins-color-grey-h);
  --prins-color-grey-dark2-s: var(--prins-color-grey-s);
  --prins-color-grey-dark2-l: 30%;
  --prins-color-grey-dark2: hsl(var(--prins-color-grey-dark2-h), var(--prins-color-grey-dark2-s), var(--prins-color-grey-dark2-l));
  --prins-color-white-h: 0;
  --prins-color-white-s: 0%;
  --prins-color-white-l: 100%;
  --prins-color-white: hsl(var(--prins-color-white-h), var(--prins-color-white-s), var(--prins-color-white-l));
  --prins-color-text-h: 0;
  --prins-color-text-s: 0%;
  --prins-color-text-l: 0%;
  --prins-color-text: hsl(var(--prins-color-text-h), var(--prins-color-text-s), var(--prins-color-text-l));
  --prins-color-text-muted-h: 0;
  --prins-color-text-muted-s: 0%;
  --prins-color-text-muted-l: 43%;
  --prins-color-text-muted: hsl(var(--prins-color-text-muted-h), var(--prins-color-text-muted-s), var(--prins-color-text-muted-l));
  --prins-color-primary-h: 249;
  --prins-color-primary-s: 52%;
  --prins-color-primary-l: 46%;
  --prins-color-primary: hsl(var(--prins-color-primary-h), var(--prins-color-primary-s), var(--prins-color-primary-l));
  --prins-color-primary-dark-h: var(--prins-color-primary-h);
  --prins-color-primary-dark-s: 32%;
  --prins-color-primary-dark-l: 12%;
  --prins-color-primary-dark: hsl(var(--prins-color-primary-dark-h), var(--prins-color-primary-dark-s), var(--prins-color-primary-dark-l));
  --prins-color-primary-dark-contrast-h: var(--prins-color-primary-h);
  --prins-color-primary-dark-contrast-s: 32%;
  --prins-color-primary-dark-contrast-l: 100%;
  --prins-color-primary-dark-contrast: hsl(var(--prins-color-primary-dark-contrast-h), var(--prins-color-primary-dark-contrast-s), var(--prins-color-primary-dark-contrast-l));
  --prins-color-primary-contrast-h: 0;
  --prins-color-primary-contrast-s: 0%;
  --prins-color-primary-contrast-l: 100%;
  --prins-color-primary-contrast: hsl(var(--prins-color-primary-contrast-h), var(--prins-color-primary-contrast-s), var(--prins-color-primary-contrast-l));
  --prins-color-secondary-h: 240;
  --prins-color-secondary-s: 32%;
  --prins-color-secondary-l: 12%;
  --prins-color-secondary: hsl(var(--prins-color-secondary-h), var(--prins-color-secondary-s), var(--prins-color-secondary-l));
  --prins-color-secondary-contrast-h: 0;
  --prins-color-secondary-contrast-s: 0%;
  --prins-color-secondary-contrast-l: 100%;
  --prins-color-secondary-contrast: hsl(var(--prins-color-secondary-contrast-h), var(--prins-color-secondary-contrast-s), var(--prins-color-secondary-contrast-l));
  --prins-color-default-h: 220;
  --prins-color-default-s: 4%;
  --prins-color-default-l: 73%;
  --prins-color-default: hsl(var(--prins-color-default-h), var(--prins-color-default-s), var(--prins-color-default-l));
  --prins-color-default-contrast-h: 0;
  --prins-color-default-contrast-s: 0%;
  --prins-color-default-contrast-l: 0%;
  --prins-color-default-contrast: hsl(var(--prins-color-default-contrast-h), var(--prins-color-default-contrast-s), var(--prins-color-default-contrast-l));
  --prins-color-enabled-h: 170;
  --prins-color-enabled-s: 100%;
  --prins-color-enabled-l: 37%;
  --prins-color-enabled: hsl(var(--prins-color-enabled-h), var(--prins-color-enabled-s), var(--prins-color-enabled-l));
  --prins-color-enabled-contrast-h: 0;
  --prins-color-enabled-contrast-s: 0%;
  --prins-color-enabled-contrast-l: 100%;
  --prins-color-enabled-contrast: hsl(var(--prins-color-enabled-contrast-h), var(--prins-color-enabled-contrast-s), var(--prins-color-enabled-contrast-l));
  --prins-color-disabled-h: 44;
  --prins-color-disabled-s: 100%;
  --prins-color-disabled-l: 52%;
  --prins-color-disabled: hsl(var(--prins-color-disabled-h), var(--prins-color-disabled-s), var(--prins-color-disabled-l));
  --prins-color-disabled-contrast-h: 0;
  --prins-color-disabled-contrast-s: 0%;
  --prins-color-disabled-contrast-l: 100%;
  --prins-color-disabled-contrast: hsl(var(--prins-color-disabled-contrast-h), var(--prins-color-disabled-contrast-s), var(--prins-color-disabled-contrast-l));
  --prins-color-danger-h: 11;
  --prins-color-danger-s: 85%;
  --prins-color-danger-l: 66%;
  --prins-color-danger: hsl(var(--prins-color-danger-h), var(--prins-color-danger-s), var(--prins-color-danger-l));
  --prins-color-danger-contrast-h: 0;
  --prins-color-danger-contrast-s: 0%;
  --prins-color-danger-contrast-l: 100%;
  --prins-color-danger-contrast: hsl(var(--prins-color-danger-contrast-h), var(--prins-color-danger-contrast-s), var(--prins-color-danger-contrast-l));
  --prins-color-success-h: 170;
  --prins-color-success-s: 100%;
  --prins-color-success-l: 37%;
  --prins-color-success: hsl(var(--prins-color-success-h), var(--prins-color-success-s), var(--prins-color-success-l));
  --prins-color-success-contrast-h: 0;
  --prins-color-success-contrast-s: 0%;
  --prins-color-success-contrast-l: 100%;
  --prins-color-success-contrast: hsl(var(--prins-color-success-contrast-h), var(--prins-color-success-contrast-s), var(--prins-color-success-contrast-l));
  --prins-color-warning-h: 44;
  --prins-color-warning-s: 100%;
  --prins-color-warning-l: 52%;
  --prins-color-warning: hsl(var(--prins-color-warning-h), var(--prins-color-warning-s), var(--prins-color-warning-l));
  --prins-color-warning-contrast-h: 0;
  --prins-color-warning-contrast-s: 0%;
  --prins-color-warning-contrast-l: 100%;
  --prins-color-warning-contrast: hsl(var(--prins-color-warning-contrast-h), var(--prins-color-warning-contrast-s), var(--prins-color-warning-contrast-l));
  --prins-color-info-h: 201;
  --prins-color-info-s: 72%;
  --prins-color-info-l: 52%;
  --prins-color-info: hsl(var(--prins-color-info-h), var(--prins-color-info-s), var(--prins-color-info-l));
  --prins-color-info-contrast-h: 0;
  --prins-color-info-contrast-s: 0%;
  --prins-color-info-contrast-l: 100%;
  --prins-color-info-contrast: hsl(var(--prins-color-info-contrast-h), var(--prins-color-info-contrast-s), var(--prins-color-info-contrast-l));
  --prins-color-new-h: 220;
  --prins-color-new-s: 4%;
  --prins-color-new-l: 73%;
  --prins-color-new: hsl(var(--prins-color-new-h), var(--prins-color-new-s), var(--prins-color-new-l));
  --prins-color-new-contrast-h: 0;
  --prins-color-new-contrast-s: 0%;
  --prins-color-new-contrast-l: 0%;
  --prins-color-new-contrast: hsl(var(--prins-color-new-contrast-h), var(--prins-color-new-contrast-s), var(--prins-color-new-contrast-l));
  --prins-font-family-sans: Inter, 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --prins-font-family-heading: Inter, 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --prins-font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  --prins-root-font-size: 14px;
  --prins-font-size-xxs: calc(var(--prins-root-font-size) - 3px);
  --prins-font-size-xs: calc(var(--prins-root-font-size) - 2px);
  --prins-font-size-s: calc(var(--prins-root-font-size) - 1px);
  --prins-font-size-l: calc(var(--prins-root-font-size) + 1px);
  --prins-font-size-xl: calc(var(--prins-root-font-size) + 2px);
  --prins-font-size-xxl: calc(var(--prins-root-font-size) + 4px);
  --prins-h1-font-size: calc(var(--prins-root-font-size) - 1px);
  --prins-h2-font-size: calc(var(--prins-root-font-size) + 0px);
  --prins-h3-font-size: calc(var(--prins-root-font-size) + 0px);
  --prins-h4-font-size: calc(var(--prins-root-font-size) + 0px);
  --prins-h5-font-size: calc(var(--prins-root-font-size) + 0px);
  --prins-h6-font-size: calc(var(--prins-root-font-size) + 0px);
  --prins-font-weight-normal: 400;
  --prins-font-weight-medium: 500;
  --prins-font-weight-semibold: 600;
  --prins-font-weight-bold: 700;
  --prins-spacing: 16px;
  --prins-spacing-xxs: calc(16px / 8);
  --prins-spacing-xs: calc(16px / 4);
  --prins-spacing-s: calc(16px / 2);
  --prins-spacing-l: calc(16px * 2);
  --prins-spacing-xl: calc(16px * 4);
  --prins-spacing-xxl: calc(16px * 8);
  --prins-grid-gap-x: var(--prins-spacing);
  --prins-grid-gap-y: var(--prins-spacing);
  --prins-breakpoint-xs: 1px;
  --prins-breakpoint-s: 576px;
  --prins-breakpoint-m: 768px;
  --prins-breakpoint-l: 992px;
  --prins-breakpoint-xl: 1200px;
  --prins-breakpoint-xxl: 1400px;
  --prins-breakpoint-xxxl: 1600px;
  --prins-zindex-app-header: 20;
  --prins-zindex-page-search: 15;
  --prins-zindex-page-scroll-top: 50;
  --prins-zindex-app-menu: 100;
  --prins-zindex-page-footer: 10;
  --prins-zindex-modal: 200;
  --prins-zindex-app-preloader: 9000;
  --prins-zindex-scroll-progress: 90;
  --prins-base-background-color: hsl(var(--prins-color-black-h), var(--prins-color-black-s), 96%);
  --prins-base-border-color: hsl(var(--prins-color-black-h), var(--prins-color-black-s), 90%);
  --prins-base-border-width: 1px;
  --prins-base-text-color: var(--prins-color-black);
  --prins-base-font-family: var(--prins-font-family-sans);
  --prins-base-font-size: 1rem;
  --prins-base-font-weight: 400;
  --prins-base-line-height: 1.3125;
  --prins-base-disabled-opacity: 0.3;
  --prins-base-page-max-width: 1600px;
  --prins-current-menu-width: 0px; /* stylelint-disable-line length-zero-no-unit */
  --prins-breakpoint-menu-canvas: 1200px;
  --prins-breakpoint-module-filter: 1200px;
  --prins-scaffolding-duration: 0.001s;
  --prins-scaffolding-timing-function: ease-in-out;
  --prins-menu-color: var(--prins-color-primary-dark-contrast);
  --prins-menu-background-color: var(--prins-color-primary-dark);
  --prins-menu-width: 90%;
  --prins-menu-minimized-width: 100px;
  --prins-header-height: 60px;
  --prins-header-menu-padding: 1rem;
  --prins-page-offset-x: 1rem;
  --prins-page-offset-y: 1rem;
  --prins-focus-ring-h: var(--prins-color-primary-h);
  --prins-focus-ring-s: var(--prins-color-primary-s);
  --prins-focus-ring-l: var(--prins-color-primary-l);
  --prins-focus-ring-alpha: 0.25;
  --prins-focus-ring-width: 3px;
  --prins-focus-ring-box-shadow: 0 0 0 var(--prins-focus-ring-width) hsla(var(--prins-focus-ring-h), var(--prins-focus-ring-s), var(--prins-focus-ring-l), var(--prins-focus-ring-alpha));
  --prins-focus-ring-box-shadow-inset: inset 0 0 0 var(--prins-focus-ring-width) hsla(var(--prins-focus-ring-h), var(--prins-focus-ring-s), var(--prins-focus-ring-l), var(--prins-focus-ring-alpha));
  --prins-focus-outline-color: currentColor;
  --prins-focus-outline-offset: 2px;
  --prins-focus-outline-style: dotted;
  --prins-focus-outline-width: 2px;
  --prins-link-color: currentColor;
  --prins-link-color-hover: var(--prins-color-primary);
  --prins-muted-text-color: hsl(var(--prins-color-grey-h), var(--prins-color-grey-s), 46%);
  --prins-content-element-margin: var(--prins-spacing);
  --prins-block-element-margin: var(--prins-spacing);
  --prins-selection-background-color: var(--prins-color-primary);
  --prins-selection-color: var(--prins-color-primary-contrast);
  --prins-backdrop-color-h: var(--prins-color-secondary-h);
  --prins-backdrop-color-s: var(--prins-color-secondary-s);
  --prins-backdrop-color-l: var(--prins-color-secondary-l);
  --prins-backdrop-color: hsl(var(--prins-color-secondary-h), var(--prins-color-secondary-s), var(--prins-color-secondary-l));
  --prins-backdrop-filter: none;
  --prins-backdrop-opacity: 0.75;
  --prins-ratio-16x9: calc(9 / 16 * 100%);
  --prins-ratio-4x3: calc(3 / 4 * 100%);
  --prins-ratio-3x4: calc(4 / 3 * 100%);
  --prins-ratio-3x2: calc(2 / 3 * 100%);
  --prins-ratio-2x3: calc(3 / 2 * 100%);
  --prins-ratio-1x1: calc(1 / 1 * 100%);
  --prins-border-width: 1px;
  --prins-border-color-h: var(--prins-color-grey-h);
  --prins-border-color-s: var(--prins-color-grey-s);
  --prins-border-color-l: 92%;
  --prins-border-color: hsl(var(--prins-border-color-h), var(--prins-border-color-s), var(--prins-border-color-l));
  --prins-box-shadow-color: hsla(var(--prins-color-black-h), var(--prins-color-black-s), var(--prins-color-black-l), var(--prins-box-shadow-color-alpha, 0.15));
  --prins-box-shadow-size: 0 0.125rem 0.25rem 0;
  --prins-box-shadow-size-event: 0 0.25rem 0.75rem 0;
  --prins-border-radius-xs: 2px;
  --prins-border-radius-s: 4px;
  --prins-border-radius-m: 8px;
  --prins-border-radius-l: 16px;
  --prins-border-radius: var(--prins-border-radius-s);
  --prins-transition-average: calc(var(--global-duration-multiplier, 1) * 0.25s);
  --prins-transition-complex: calc(var(--global-duration-multiplier, 1) * 0.5s);
  --prins-transition-check: calc(var(--global-duration-multiplier, 1) * 0.15s);
  --prins-transition-expand: calc(var(--global-duration-multiplier, 1) * 0.3s);
  --prins-transition-collape: calc(var(--global-duration-multiplier, 1) * 0.25s);
  --prins-transition-open: calc(var(--global-duration-multiplier, 1) * 0.25s);
  --prins-transition-close: calc(var(--global-duration-multiplier, 1) * 0.2s);
  --prins-transition-simple: calc(var(--global-duration-multiplier, 1) * 0.15s);
  --prins-transition: calc(var(--global-duration-multiplier, 1) * 0.2s);
  --prins-scrollbar-size: 4px;
  --prins-scrollbar-color: var(--prins-color-grey-dark);
}

@media (min-width: 576px) {
  :root {
    --prins-menu-width: 360px;
  }
}
@media (min-width: 768px) {
  :root {
    --prins-menu-width: 380px;
  }
}
@media (min-width: 992px) {
  :root {
    --prins-menu-width: 315px;
    --prins-page-offset-x: 2rem;
    --prins-page-offset-y: 2rem;
    --prins-grid-gap-x: var(--prins-spacing-l);
    --prins-grid-gap-y: var(--prins-spacing-l);
    --prins-content-element-margin: var(--prins-spacing-l);
  }
}
@media (min-width: 1200px) {
  :root {
    --prins-sidebar-width: 25rem;
  }
}
@media (min-width: 1400px) {
  :root {
    --prins-header-height: 70px;
    --prins-sidebar-width: 30rem;
  }
}
@media (prefers-reduced-motion: reduce) {
  :root {
    --global-duration-multiplier: 0.0; /* stylelint-disable-line number-no-trailing-zeros */
  }
}
:root {
  font-size: var(--prins-root-font-size);
  scroll-behavior: smooth;
}
@media (prefers-reduced-motion: reduce) {
  :root {
    scroll-behavior: auto;
  }
}

html {
  background-color: var(--prins-base-background-color);
  box-sizing: border-box;
  min-height: 100%;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}
@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
html.browser-not-supported {
  background-color: #fff !important; /* stylelint-disable-line declaration-no-important */
}
html.browser-not-supported .browser-not-supported__info {
  background-color: #000;
  color: #fff;
  display: block;
  left: 50%;
  max-width: 32rem;
  padding: 16px 32px;
  position: fixed;
  text-align: center;
  top: 1rem;
  transform: translateX(-50%);
  width: 90%;
}

body {
  background-color: transparent;
  color: var(--prins-base-text-color);
  font-family: var(--prins-base-font-family);
  font-size: var(--prins-base-font-size);
  font-style: normal;
  font-variant: normal;
  font-weight: var(--prins-base-font-weight);
  line-height: var(--prins-base-line-height);
  min-height: calc(var(--prins-vh, 1vh) * 100);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
body {
  scrollbar-face-color: var(--prins-scrollbar-color, #000);
  scrollbar-track-color: var(--prins-scrollbar-track-color, transparent);
}
body::-webkit-scrollbar {
  height: var(--prins-scrollbar-size, 4px);
  width: var(--prins-scrollbar-size, 4px);
}
body::-webkit-scrollbar-thumb {
  background: var(--prins-scrollbar-color, #000);
}
body::-webkit-scrollbar-track {
  background: var(--prins-scrollbar-track-color, transparent);
}
.page-complete body {
  overflow-y: auto;
  pointer-events: all;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

::selection {
  background-color: var(--prins-selection-background-color);
  color: var(--prins-selection-color);
}

svg:not(:root) {
  overflow: hidden;
}

:focus-visible {
  outline: var(--focus-outline-color, var(--prins-focus-outline-color)) var(--prins-focus-outline-style) var(--prins-focus-outline-width);
  outline-offset: var(--prins-focus-outline-offset);
}

/* stylelint-disable string-quotes */
a:focus,
button:focus,
[type=submit]:focus,
[type=button]:focus {
  box-shadow: none;
  outline: none;
}
a._focus-visible:focus, a.focus-visible:focus, a:focus-visible:focus,
button._focus-visible:focus,
button.focus-visible:focus,
button:focus-visible:focus,
[type=submit]._focus-visible:focus,
[type=submit].focus-visible:focus,
[type=submit]:focus-visible:focus,
[type=button]._focus-visible:focus,
[type=button].focus-visible:focus,
[type=button]:focus-visible:focus {
  outline: var(--focus-outline-color, var(--prins-focus-outline-color)) var(--prins-focus-outline-style) var(--prins-focus-outline-width);
  outline-offset: var(--prins-focus-outline-offset);
}

/* stylelint-enable string-quotes */
:any-link,
.link {
  --prins-link-color: currentColor;
  --prins-link-color-hover: var(--prins-link-color);
  --prins-link-icon-color: var(--prins-link-color);
  --prins-link-icon-color-hover: var(--prins-link-color-hover);
  --prins-link-text-decoration-line: underline;
  --prins-link-text-decoration-style: solid;
  --prins-link-text-decoration-hover: var(--prins-link-text-decoration);
  --prins-link-gap: var(--prins-spacing-xs);
  --prins-focus-outline-color: currentColor;
  --_link-icon-color: var(--prins-link-icon-color);
  --_link-duration: var(--prins-transition);
  color: var(--prins-link-color);
  font-weight: var(--prins-link-font-weight, inherit);
  text-decoration: var(--prins-link-text-decoration-line) var(--prins-link-text-decoration-style) var(--prins-link-text-decoration-color, var(--prins-link-color));
  text-decoration-thickness: max(1px, 0.0625em);
  text-underline-offset: 0.125em;
  transition: color var(--_link-duration), background-color var(--prins-transition), border-color var(--prins-transition);
  /* stylelint-disable string-quotes */
  /* stylelint-enable string-quotes */
}
:any-link > i,
.link > i {
  color: var(--_link-icon-color);
  margin-right: var(--prins-link-gap);
}
:any-link[href*="tel:"], :any-link[href*="fax:"],
.link[href*="tel:"],
.link[href*="fax:"] {
  color: var(--prins-link-color);
  font-weight: inherit;
  text-decoration: none;
}
:any-link:hover, :any-link:active, :any-link:focus, :any-link:focus-visible,
a.link:hover,
a.link:active,
a.link:focus,
a.link:focus-visible {
  --_link-icon-color: var(--prins-link-icon-color-hover);
  color: var(--prins-link-color-hover);
}
:any-link:hover, :any-link:focus,
a.link:hover,
a.link:focus {
  cursor: pointer;
}

:any-link._disabled,
.link._disabled {
  --prins-link-text-decoration-line: line-through;
  color: var(--prins-link-color);
  pointer-events: none;
}
:any-link._disabled *,
.link._disabled * {
  pointer-events: none;
}

.link {
  display: inline-flex;
  gap: var(--prins-link-gap, 0.3125em);
}
.link__prefix {
  color: var(--_link-icon-color);
  padding-left: var(--prins-link-gap);
}
.link__suffix {
  color: var(--_link-icon-color);
  padding-right: var(--prins-link-gap);
}

a[data-icon] {
  --prins-link-text-decoration-line: none;
}

hr {
  background-color: currentColor;
  border: 0;
  color: var(--prins-hr-color, var(--prins-border-color));
  margin: var(--prins-hr-margin, var(--prins-block-element-margin)) 0;
  opacity: 1;
}
hr:not([size]) {
  height: 1px;
}

p {
  margin: 0 0 var(--prins-paragraph-margin, var(--prins-block-element-margin));
}

blockquote {
  margin: 0 0 var(--prins-blockquote-margin, var(--prins-block-element-margin));
}

table,
.table {
  margin: 0 0 var(--prins-table-margin, var(--prins-block-element-margin));
  width: 100%;
}

iframe {
  border: 0 none;
  min-width: 100%;
}

figure,
.figure,
picture,
.picture {
  background-color: transparent;
  border: 0 none;
  border-radius: 0;
  display: block;
  padding: 0;
  position: relative;
  width: 100%;
}

img {
  border: 0;
  display: block;
  height: auto;
  max-width: 100%;
}
img.is-faulty {
  background-color: var(--prins-color-danger);
}
img.is-faulty::before {
  align-items: center;
  color: var(--prins-color-danger-contrast);
  content: "404"; /* stylelint-disable-line string-quotes */
  display: flex;
  font-size: 10px;
  height: 100%;
  hyphens: manual;
  justify-content: center;
  line-height: 1;
  overflow-wrap: break-word;
  text-align: center;
}

.time__time {
  display: none;
}
.time__time::before {
  content: ', ';
}

/* stylelint-disable string-quotes */
[disabled]:not([disabled=false]),
._disabled {
  pointer-events: none;
}

/* stylelint-enable string-quotes */
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: var(--prins-heading-color, var(--prins-base-text-color));
  display: block;
  font-family: var(--prins-heading-font-family, var(--prins-font-family-heading));
  font-size: var(--prins-heading-font-size, 1rem);
  font-weight: var(--prins-heading-font-weight, var(--prins-font-weight));
  -webkit-hyphens: manual;
  -ms-hyphens: manual;
  hyphens: manual;
  line-height: var(--prins-heading-line-height, 1.125);
  margin: 0 0 var(--prins-heading-margin, 1.125em);
  overflow-wrap: break-word;
  width: 100%;
  word-wrap: break-word;
}
h1 > a, .h1 > a, h2 > a, .h2 > a, h3 > a, .h3 > a, h4 > a, .h4 > a, h5 > a, .h5 > a, h6 > a, .h6 > a {
  color: inherit;
}
h1:first-child, .h1:first-child, h2:first-child, .h2:first-child, h3:first-child, .h3:first-child, h4:first-child, .h4:first-child, h5:first-child, .h5:first-child, h6:first-child, .h6:first-child {
  margin-top: 0;
}
h1:last-child, .h1:last-child, h2:last-child, .h2:last-child, h3:last-child, .h3:last-child, h4:last-child, .h4:last-child, h5:last-child, .h5:last-child, h6:last-child, .h6:last-child {
  margin-bottom: 0;
}

h1,
.h1 {
  --prins-heading-font-size: var(--prins-h1-font-size);
}

h2,
.h2 {
  --prins-heading-font-size: var(--prins-h2-font-size);
}

h3,
.h3 {
  --prins-heading-font-size: var(--prins-h3-font-size);
}

h4,
.h4 {
  --prins-heading-font-size: var(--prins-h4-font-size);
}

h5,
.h5 {
  --prins-heading-font-size: var(--prins-h5-font-size);
}

h6,
.h6 {
  --prins-heading-font-size: var(--prins-h6-font-size);
}

em,
.em {
  font-style: italic;
}

small,
.small,
.text-small {
  font-size: var(--prins-font-size-s);
}

mark,
.mark {
  --prins-mark-background-color: var(--prins-color-info);
  --prins-mark-color: var(--prins-color-info-contrast);
  background-color: var(--prins-mark-background-color);
  color: var(--prins-mark-color);
  padding: 0 var(--prins-spacing-xs);
}

del,
s {
  text-decoration-line: line-through;
}

del {
  color: var(--prins-del-color, hsl(var(--prins-color-black-h), var(--prins-color-black-s), 70%));
}

b,
strong,
.strong {
  font-weight: var(--prins-font-weight-bold);
}

.text-color-primary {
  color: var(--prins-color-primary);
}
.text-color-primary a:not(.button) {
  --prins-link-color: currentColor;
}
.text-color-secondary {
  color: var(--prins-color-secondary);
}
.text-color-secondary a:not(.button) {
  --prins-link-color: currentColor;
}
.text-color-danger {
  color: var(--prins-color-danger);
}
.text-color-danger a:not(.button) {
  --prins-link-color: currentColor;
}
.text-color-success {
  color: var(--prins-color-success);
}
.text-color-success a:not(.button) {
  --prins-link-color: currentColor;
}
.text-color-warning {
  color: var(--prins-color-warning);
}
.text-color-warning a:not(.button) {
  --prins-link-color: currentColor;
}
.text-color-info {
  color: var(--prins-color-info);
}
.text-color-info a:not(.button) {
  --prins-link-color: currentColor;
}
.text-color- {
  color: var(--prins-color-);
}
.text-color- a:not(.button) {
  --prins-link-color: currentColor;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.text-align-center {
  text-align: center;
}
.text-align-justify {
  text-align: justify;
}
.text-size-xxs {
  font-size: var(--prins-font-size-xxs);
}
.text-size-xs {
  font-size: var(--prins-font-size-xs);
}
.text-size-s {
  font-size: var(--prins-font-size-s);
}
.text-size-l {
  font-size: var(--prins-font-size-l);
}
.text-size-xl {
  font-size: var(--prins-font-size-xl);
}
.text-size-xxl {
  font-size: var(--prins-font-size-xxl);
}

.subtitle::before {
  content: "("; /* stylelint-disable-line string-quotes */
  padding-right: 0.125em;
}
.subtitle::after {
  content: ")"; /* stylelint-disable-line string-quotes */
  padding-left: 0.125em;
}

.text-muted {
  color: var(--prins-muted-text-color, inherit) !important; /* stylelint-disable-line declaration-no-important */
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.line-clamp {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: var(--prins-line-clamp, 2);
  overflow: hidden;
}
.line-clamp[data-line-clamp-switchable] {
  cursor: pointer;
}
.line-clamp[data-line-clamp-switchable] > * {
  pointer-events: none;
}

.app-preloader {
  --_size: 3em;
  --_offset: calc(-1 * var(--_size) / 2);
  background-color: var(--prins-base-background-color);
  bottom: 0;
  color: var(--prins-color-primary);
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--prins-zindex-app-preloader);
}
.app-preloader .spinner {
  --ifabsh-spinner-size: 3em;
  left: 50%;
  margin: var(--_offset) 0 0 var(--_offset);
  position: absolute;
  top: 50%;
}
[data-preloader=disabled] .app-preloader { /* stylelint-disable-line string-quotes */
  display: none;
}

.app-logo {
  --prins-link-text-decoration-line: none;
  --app-logo-color: var(--prins-color-primary);
  --app-logo-height: 1.75rem;
  --app-logo-width: auto;
  display: inline-block;
  position: relative;
}
.app-logo > [data-sym=minimized] { /* stylelint-disable-line string-quotes */
  left: 50%;
  position: absolute;
  top: 50%;
  translate: -50% -50%;
}
.app-logo > :not([data-sym=default]) { /* stylelint-disable-line string-quotes */
  opacity: 0;
}
.app-logo > svg,
.app-logo > img {
  height: var(--app-logo-height);
  pointer-events: none;
  width: var(--app-logo-width);
}

.app-context {
  background-color: var(--prins-color-secondary);
  bottom: 0;
  color: var(--prins-color-secondary-contrast);
  font-size: var(--prins-font-size-xxs);
  left: 50%;
  padding: var(--prins-spacing-xs) var(--prins-spacing-s);
  pointer-events: none;
  position: fixed;
  text-align: center;
  text-transform: uppercase;
  translate: -50% 0;
  z-index: calc(var(--prins-zindex-app-preloader) + 1);
}

@keyframes landing-header-come-in {
  0% {
    opacity: 0;
    transform: translateY(4rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.app-landing {
  --_landing-duration-multiplier: var(--global-duration-multiplier);
  --_landing-header-height: calc(var(--prins-header-height) * 2.5);
  --_landing-logo-size: calc(var(--prins-header-height) / 2);
  display: flex;
  flex-flow: column nowrap;
  min-height: calc(var(--prins-vh, 1vh) * 100);
}
.app-landing__header {
  --_delay: calc(var(--_landing-duration-multiplier, 1) * 1.25s);
  --_duration: calc(var(--_landing-duration-multiplier, 1) * 0.5s);
  align-items: center;
  animation: var(--_duration) ease-out var(--_delay) 1 forwards landing-header-come-in;
  color: var(--prins-color-secondary-contrast);
  display: flex;
  flex-flow: column nowrap;
  gap: var(--prins-spacing);
  height: var(--_landing-header-height);
  justify-content: center;
  opacity: 0;
  padding: 0 var(--prins-page-offset-x);
  transform: translateY(4rem);
}
.app-landing__header .app-logo {
  --app-logo-color: currentColor;
  --app-logo-height: var(--_landing-logo-size);
  --app-logo-accent-color: var(--prins-color-primary);
}
.app-landing__header .badge {
  --ifabsh-badge-background-color: var(--prins-color-primary);
  --ifabsh-badge-border-color: var(--prins-color-primary);
  --ifabsh-badge-color: var(--prins-color-primary-contrast);
}
.app-landing__footer {
  color: hsl(var(--prins-color-black-h), var(--prins-color-black-s), 32%);
  display: flex;
  font-size: var(--prins-font-size-xxs);
  gap: var(--prins-spacing);
  justify-content: space-between;
  padding: var(--prins-spacing);
  text-align: center;
}
.app-landing__footer :any-link {
  --prins-link-text-decoration-line: none;
}
.app-landing__footer > *:first-child:last-child {
  min-width: 100%;
}
.app-landing__body {
  --_delay: calc(var(--_landing-duration-multiplier, 1) * 1.5s);
  --_duration: calc(var(--_landing-duration-multiplier, 1) * 0.5s);
  flex: 1 0 auto;
  padding: 0 var(--prins-page-offset-x) var(--prins-page-offset-y);
}
.app-landing__body > * {
  animation: var(--_duration) ease-out var(--_delay) 1 forwards landing-header-come-in;
  opacity: 0;
  transform: translateY(4rem);
}
.app-landing .key-visual {
  height: calc(var(--_landing-header-height) * 1.25);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.app-landing__body .card {
  --ifabsh-card-background-color: var(--prins-color-white);
  --ifabsh-card-border-color: var(--prins-color-white);
  --ifabsh-card-border-width: 0px; /* stylelint-disable-line length-zero-no-unit */
  --ifabsh-card-content-gap: var(--ifabsh-card-padding);
  --ifabsh-card-title-color: var(--prins-color-secondary);
  --_delay: calc(var(--_landing-duration-multiplier, 1) * 1.5s);
  --_duration: calc(var(--_landing-duration-multiplier, 1) * 0.5s);
  box-shadow: 0 0 0.25rem hsla(var(--prins-color-secondary-h), var(--prins-color-secondary-s), var(--prins-color-secondary-l), 0.125);
  margin-left: auto;
  margin-right: auto;
  max-width: 30rem;
  width: 100%;
}
.app-landing__body .card .card-title {
  font-size: var(--prins-font-size-xxl);
}
.app-landing__body .card .text {
  color: var(--prins-muted-text-color);
  text-align: center;
}
.app-landing__body .card .card__header {
  flex-flow: column nowrap;
  gap: var(--prins-spacing-s);
  padding-bottom: 0;
  text-align: center;
}
.app-landing__body .card .card__header .text {
  font-size: var(--prins-font-size-xs);
  margin: 0;
}
.app-landing__body .card .card__body > .context-icon {
  color: var(--ifabsh-card-title-color);
  font-size: 3rem;
  margin-block-start: calc(-1 * var(--ifabsh-card-content-gap) / 2);
}
.app-landing__body .card .card__body > .context-icon + * {
  margin-block-start: var(--ifabsh-card-content-gap);
}
.app-landing__body .card .card__body + .card__footer {
  padding-top: 0;
}
.app-landing__body .card .card__footer > *:first-child:last-child {
  flex-grow: 1;
}
@media (min-width: 992px) {
  .app-landing__body .card {
    --ifabsh-card-padding: var(--prins-spacing-l);
  }
}

[data-page-context=error] { /* stylelint-disable-line string-quotes */ }
[data-page-context=error] .app-landing .key-visual {
  --prins-key-visual-overlay-color-b1: hsla(var(--prins-color-secondary-h), var(--prins-color-secondary-s), var(--prins-color-secondary-l), 0.8);
  --prins-key-visual-overlay-color-b2: hsla(var(--prins-color-secondary-h), var(--prins-color-secondary-s), var(--prins-color-secondary-l), 0);
  --prins-key-visual-overlay-color-c1: hsla(var(--prins-color-warning-h), var(--prins-color-warning-s), var(--prins-color-warning-l), 1);
  --prins-key-visual-overlay-color-c2: hsla(var(--prins-color-warning-h), var(--prins-color-warning-s), var(--prins-color-warning-l), 0);
}
[data-page-context=error] .app-landing__header .app-logo {
  --app-logo-accent-color: var(--prins-color-warning);
}
[data-page-context=error] .app-landing__header .badge {
  --ifabsh-badge-background-color: var(--prins-color-warning);
  --ifabsh-badge-border-color: var(--prins-color-warning);
  --ifabsh-badge-color: var(--prins-color-warning-contrast);
}
[data-page-context=error] .app-landing .card {
  --ifabsh-card-title-color: var(--prins-color-danger);
}
[data-page-context=error] .app-landing .card__body {
  text-align: center;
}
[data-page-context=error] .app-landing .card__body .context-icon {
  --fa-animation-duration: 1s;
  --fa-animation-delay: 0s;
}
[data-page-context=error] .app-landing .card.-warning {
  --ifabsh-card-title-color: var(--prins-color-warning);
}